import { KurationInitialListItem } from "src/models/InitialList";
import { useState, useRef, useEffect } from "react";
import GoogleMapList from "../GoogleMapList";
import SalesNavSearchList from "../SalesNavSearchList";
import LinkedInPostCommenter from "../LinkedInPostCommenter";
import GoogleJobList from "../GoogleJobList";
import ParentCompanyLookup from "../ParentCompanyLookup";
import CompanyDetailsLookup from "../CompanyDetailsLookup";
import CSVFileUploader from "../CSVFileUploader";
import StartManually from "../StartManually";
import PeopleFinder from "../PeopleFinder";
import IndeedJobScraper from "../IndeedJobScraper";
import { useLocation } from "react-router-dom";
import LinkedinJobScrapper from "../LinkedinJobScrapper";
import WebToTable from "../WebToTable";
import LookALikeSearch from "../LookALikeSearch";
import ManualCompanies from "../ManualCompanies";
import LinkedInPostLiker from "../LinkedInPostLiker";
import { kurationItems } from "../item";

export function KurationInitialLists() {
  return (
    <div className="">
      <div>
        <p className="text-3xl">Start from scratch</p>
        <p className="text-md text-lightgray">
          What list do you want to curate? First, build an initial list from the
          best sources, select one of the builder
        </p>
      </div>
      <div className="mt-6 grid grid-cols-3 gap-4">
        {/* Render the general cards */}
        {kurationItems.map((item) => (
          <InitialCard key={item._id} item={item} />
        ))}
      </div>
    </div>
  );
}

const InitialCard = ({ item }: { item: KurationInitialListItem }) => {
  const [showQueryBox, setShowQueryBox] = useState(false);
  const queryBoxRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  function handleClick() {
    setShowQueryBox(true);
  }

  function handleClose() {
    setShowQueryBox(false);
  }

  function handleClickOutside(event: MouseEvent) {
    if (
      queryBoxRef.current &&
      !queryBoxRef.current.contains(event.target as Node)
    ) {
      handleClose();
    }
  }

  useEffect(() => {
    if (showQueryBox) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    if (
      location.pathname === "/initial-list-builder/csvFileUpload" &&
      item.name === "csvFileUploader"
    ) {
      setShowQueryBox(true);
    }
  }, [location.pathname, item.name]);

  return (
    <div>
      <div
        onClick={handleClick}
        className="cursor-pointer rounded-3xl bg-white p-4 shadow-lg"
      >
        <img src={item.icon} alt="" />
        <div className="mt-2">
          <p className="text-lg ">{item.description}</p>
          <p className="text-sm text-[#6D738E]">{item.subtitle}</p>
        </div>
      </div>

      {showQueryBox && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div
            ref={queryBoxRef}
            className="relative rounded-lg bg-white p-6 shadow-lg"
          >
            {item.name === "googleMap" && (
              <GoogleMapList item={item} handleClose={handleClose} />
            )}
            {item.name === "GoogleJob" && (
              <GoogleJobList item={item} handleClose={handleClose} />
            )}
            {item.name === "salesNavSearch" && (
              <SalesNavSearchList handleClose={handleClose} />
            )}
            {item.name === "postCommenter" && (
              <LinkedInPostCommenter handleClose={handleClose} />
            )}
            {item.name === "postLiker" && (
              <LinkedInPostLiker handleClose={handleClose} />
            )}
            {item.name === "parentCompanyLookup" && (
              <ParentCompanyLookup item={item} handleClose={handleClose} />
            )}
            {item.name === "companyDetailsLookup" && (
              <CompanyDetailsLookup item={item} handleClose={handleClose} />
            )}
            {item.name === "csvFileUploader" && (
              <CSVFileUploader item={item} handleClose={handleClose} />
            )}
            {item.name === "startManually" && (
              <StartManually item={item} handleClose={handleClose} />
            )}
            {item.name === "peopleFinder" && (
              <PeopleFinder item={item} handleClose={handleClose} />
            )}
            {item.name === "indeedJobScraper" && (
              <IndeedJobScraper handleClose={handleClose} initialSearchUrl="" />
            )}
            {item.name === "linkedinJobScrapper" && (
              <LinkedinJobScrapper
                handleClose={handleClose}
                numberOfJobs={10}
                initialJobTitle=""
              />
            )}
            {item.name === "webToTable" && (
              <WebToTable handleClose={handleClose} />
            )}
            {item.name === "lookalikesearch" && (
              <LookALikeSearch
                initialDomain=""
                initialkeyword=""
                handleClose={handleClose}
              />
            )}
            {item.name === "manualCompanies" && <ManualCompanies />}

            <button
              className="absolute right-2 top-2 text-gray-600 hover:text-gray-900"
              onClick={handleClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
