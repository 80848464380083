import CloseIcon from "../svgs/CloseIcon";
import { useNavigate } from "react-router-dom";
import backend_services from "src/services/backend_service";
import Spinner from "../elements/Spinner";
import { useAuth } from "src/context/AuthContext";
import { useState } from "react";
import { TableState } from "src/hooks/useChatTable";
import { toast } from "react-toastify";
import SalesNavSearchListForm from "./forms/SalesNavSearchListForm";
import { useStateContext } from "src/context/StateContext";
import DownloadIcon from "../svgs/Download";
import { UserIcon } from "@heroicons/react/24/outline";
import CopyIcon from "../svgs/CopyIcon";
import useUserCredits from "src/hooks/useUserCredits";

interface SalesNavSearchListProps {
  handleClose: () => void;
  initialSearchURL?: string;
  initialLinkedinCookies?: string;
  table_state?: TableState;
  chat_id?: string;
}

const SalesNavSearchList: React.FC<SalesNavSearchListProps> = ({
  initialSearchURL = "",
  initialLinkedinCookies = "",
  handleClose,
  table_state,
  chat_id,
}) => {
  const { setSidebarOpen } = useStateContext();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [searchURL, setSearchURL] = useState(initialSearchURL);
  const [sessionCookies, setSessionCookies] = useState(initialLinkedinCookies);
  const navigator = useNavigate();
  const { userCredits } = useUserCredits(user?.uid ?? null);

  async function handleMessageSend() {
    try {
      setLoading(true);

      if (!user) {
        toast.error("User is not authenticated.");
        return;
      }

      if ((userCredits?.col_credits ?? 0) < 2000) {
        toast.warning(
          "Minimum of 2000 credits is required to use this scraper.",
        );
      }

      if (!searchURL || searchURL.trim() === "") {
        toast.error("Please enter a valid search URL title.");
        return;
      }

      const linkedInSalesUrlPattern =
        /^https?:\/\/(?:www\.)?linkedin\.com\/sales\/search.*$/;

      if (!linkedInSalesUrlPattern.test(searchURL)) {
        toast.error(
          "Please enter a valid LinkedIn Sales Navigator search URL.",
        );
        setLoading(false);
        return;
      }

      if (!sessionCookies || sessionCookies.trim() === "") {
        toast.error("Please enter a session cookie.");
        return;
      }

      const requestBody: any = {
        search_url: searchURL,
        linkedin_cookies: sessionCookies,
      };

      let response;

      if (table_state === TableState.ERROR) {
        // Retry endpoint
        response = await backend_services.fetchProtectedData(
          `/initial_lists/retry/salesNavSearch/${chat_id}`,
          user.getIdToken(),
          undefined,
          "POST",
          requestBody,
        );

        if (response.ok) {
          const result = await response.json();
          toast.success(result.message || "Retry initiated successfully.");
          handleClose(); // Close modal after retry
        } else {
          throw new Error(await response.text());
        }
      } else {
        // Default endpoint
        response = await backend_services.fetchProtectedData(
          `/initial_lists/salesNavSearch`,
          user.getIdToken(),
          undefined,
          "POST",
          requestBody,
        );

        if (response.ok) {
          const result = await response.json();
          if (result?.chat_id) {
            navigator(`/chat/${result.chat_id}`); // Redirect to chat only for default request
            toast.success(result.message || "Request submitted successfully.");
            handleClose();
            setSidebarOpen(false);
          } else {
            toast.error(result.detail || "An error occurred.");
          }
        } else {
          throw new Error(await response.text());
        }
      }
    } catch (error: any) {
      console.error("Error:", error.message || error);
      console.log("erryyaaaa", error.message);
      toast.error(error.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div
      className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform ${loading ? "opacity-95" : null} z-50 h-[650px] w-[700px] overflow-auto rounded-lg bg-white px-6 py-6 shadow-lg`}
    >
      {loading && <Spinner text="Searching..." onWhiteBackground />}
      <div className="flex flex-col gap-5">
        <div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <img
                src="/linkedin_icon.png"
                alt="Sales Navigator"
                className="h-12 w-12"
              />
              <div className="">
                <h2 className="text-xl font-bold">
                  Scrape Linkedin Sales Navigator
                </h2>
                <p className="text-sm">
                  Download the chrome extension to find your session cookie.
                </p>
              </div>
            </div>

            <button
              className="col-end-7 justify-self-end text-gray-500 hover:text-gray-700"
              onClick={handleClose}
            >
              <CloseIcon />
            </button>
          </div>

          <div className="flex items-center justify-center gap-4 pt-6">
            <div className="flex flex-col items-center">
              <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                <DownloadIcon className="h-6 w-6" />
              </div>
              <span className="text-sm">Install Extension</span>
            </div>
            <div className="h-[2px] w-8 bg-gray-300" />
            <div className="flex flex-col items-center">
              <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                <CopyIcon className="h-6 w-6 text-gray-700" />
              </div>
              <span className="text-sm">Paste Cookie</span>
            </div>
            <div className="h-[2px] w-8 bg-gray-300" />
            <div className="flex flex-col items-center">
              <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                <UserIcon className="h-6 w-6 text-gray-700" />
              </div>
              <span className="text-sm">Get Leads</span>
            </div>
          </div>
        </div>

        <hr className="w-full" />
        <SalesNavSearchListForm
          searchURL={searchURL}
          setSearchURL={setSearchURL}
          sessionCookies={sessionCookies}
          setSessionCookies={setSessionCookies}
          onSubmit={handleMessageSend}
        />
      </div>
    </div>
  );
};

export default SalesNavSearchList;
