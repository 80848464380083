import * as React from "react";
const KurationUpload = ({ fill = "#111010", ...props }) => (
  <svg
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.66797 23C6.93319 23 7.18754 23.1054 7.37508 23.2929C7.56261 23.4804 7.66797 23.7348 7.66797 24V27C7.66797 27.184 7.8173 27.3333 8.0013 27.3333H24.668C24.7564 27.3333 24.8412 27.2982 24.9037 27.2357C24.9662 27.1732 25.0013 27.0884 25.0013 27V24C25.0013 23.7348 25.1067 23.4804 25.2942 23.2929C25.4817 23.1054 25.7361 23 26.0013 23C26.2665 23 26.5209 23.1054 26.7084 23.2929C26.8959 23.4804 27.0013 23.7348 27.0013 24V27C27.0013 27.6188 26.7555 28.2123 26.3179 28.6499C25.8803 29.0875 25.2868 29.3333 24.668 29.3333H8.0013C7.38246 29.3333 6.78897 29.0875 6.35139 28.6499C5.9138 28.2123 5.66797 27.6188 5.66797 27V24C5.66797 23.7348 5.77333 23.4804 5.96086 23.2929C6.1484 23.1054 6.40275 23 6.66797 23Z"
      fill={fill}
    />
    <path
      d="M25.375 12.7063C25.2822 12.7992 25.1721 12.8729 25.0508 12.9232C24.9295 12.9735 24.7996 12.9993 24.6683 12.9993C24.537 12.9993 24.4071 12.9735 24.2858 12.9232C24.1645 12.8729 24.0544 12.7992 23.9616 12.7063L17.335 6.08101L17.335 22.333C17.335 22.5982 17.2296 22.8526 17.0421 23.0401C16.8545 23.2277 16.6002 23.333 16.335 23.333C16.0697 23.333 15.8154 23.2277 15.6279 23.0401C15.4403 22.8526 15.335 22.5982 15.335 22.333L15.335 6.08101L8.7083 12.7063C8.52088 12.8938 8.26668 12.9991 8.00163 12.9991C7.73658 12.9991 7.48238 12.8938 7.29496 12.7063C7.10754 12.5189 7.00225 12.2647 7.00225 11.9997C7.00225 11.7346 7.10754 11.4804 7.29496 11.293L15.6283 2.95967C15.7211 2.86679 15.8312 2.7931 15.9525 2.74282C16.0737 2.69255 16.2037 2.66667 16.335 2.66667C16.4662 2.66667 16.5962 2.69255 16.7175 2.74282C16.8387 2.7931 16.9489 2.86679 17.0416 2.95967L25.375 11.293C25.4678 11.3858 25.5415 11.4959 25.5918 11.6172C25.6421 11.7384 25.668 11.8684 25.668 11.9997C25.668 12.1309 25.6421 12.2609 25.5918 12.3822C25.5415 12.5034 25.4678 12.6136 25.375 12.7063Z"
      fill={fill}
    />
  </svg>
);
export default KurationUpload;
