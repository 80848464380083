import * as React from "react";
const KurationPeople = ({ stroke = "#5733CF", ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    stroke={stroke}
    {...props}
  >
    <path d="M29.16 2.84c-1.32-1.33-3.8-1-7.35 1A40.27 40.27 0 0 0 16 7.94a40.87 40.87 0 0 0-5.9-4.11c-3.55-2-6-2.32-7.35-1-2.36 2.36 1 8.26 5.1 13.21-4.51 5.46-7.31 11-5.1 13.2a2.86 2.86 0 0 0 2.13.76 9 9 0 0 0 3.53-.93 1 1 0 1 0-.82-1.82c-2 .91-3.11.86-3.43.55-.76-.77.56-4.78 5-10.25a.39.39 0 0 1 .08.1 1 1 0 1 0 1.5-1.34c-.1-.1-.18-.2-.28-.31.81-.91 1.69-1.85 2.66-2.82S15 11.4 16 10.57c.94.83 1.89 1.71 2.82 2.65s1.86 1.91 2.66 2.82c-.09.1-.17.2-.26.29a1 1 0 0 0 1.5 1.34l.06-.08c4.45 5.47 5.78 9.48 5 10.25-.31.3-1.37.35-3.35-.51a1 1 0 0 0-1.32.51 1 1 0 0 0 .52 1.32A8.83 8.83 0 0 0 27 30a2.86 2.86 0 0 0 2.13-.79c2.21-2.2-.59-7.74-5.1-13.2 4.1-4.91 7.49-10.81 5.13-13.17zm-17.45 9c-.87.88-1.72 1.79-2.54 2.7-4.42-5.43-5.83-9.43-5-10.25.32-.32 1.78-.46 5 1.33a37 37 0 0 1 5.28 3.68c-.94.76-1.85 1.61-2.74 2.5zm8.49 0c-.89-.89-1.8-1.74-2.7-2.54a37.55 37.55 0 0 1 5.28-3.68c3.2-1.79 4.65-1.65 5-1.33.82.82-.59 4.82-5 10.25-.85-.95-1.7-1.86-2.58-2.74z" />
    <path d="M18.81 21.7A4.81 4.81 0 0 0 20 18.5a4.27 4.27 0 0 0-4-4.5 4.27 4.27 0 0 0-4 4.5 4.81 4.81 0 0 0 1.19 3.2A4.58 4.58 0 0 0 11 25.59v2.31a2.1 2.1 0 0 0 2.1 2.1h5.8a2.1 2.1 0 0 0 2.1-2.1v-2.31a4.58 4.58 0 0 0-2.19-3.89zM16 16a2.3 2.3 0 0 1 2 2.5 2.3 2.3 0 0 1-2 2.5 2.3 2.3 0 0 1-2-2.5 2.3 2.3 0 0 1 2-2.5zm3 11.9a.09.09 0 0 1-.1.1h-5.8a.09.09 0 0 1-.1-.1v-2.31A2.59 2.59 0 0 1 15.59 23h.82A2.59 2.59 0 0 1 19 25.59z" />
  </svg>
);

export default KurationPeople;
