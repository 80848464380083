import CloseIcon from "../svgs/CloseIcon";
import { useNavigate } from "react-router-dom";
import backend_services from "src/services/backend_service";
import Spinner from "../elements/Spinner";
import { useAuth } from "src/context/AuthContext";
import { useState } from "react";
import { TableState } from "src/hooks/useChatTable";
import { toast } from "react-toastify";
import LinkedInPostLikerForm from "./forms/LinkedInPostLikerForm";
import { useStateContext } from "src/context/StateContext";
import DownloadIcon from "../svgs/Download";
import { UserIcon } from "@heroicons/react/24/outline";
import CopyIcon from "../svgs/CopyIcon";
import InstallChromeExtension from "../elements/InstallChromeExtension";

interface LinkedInPostLikerProps {
  handleClose: () => void;
  initialPostURL?: string;
  initialLinkedinCookies?: string;
  table_state?: TableState;
  chat_id?: string;
}

const LinkedInPostLiker: React.FC<LinkedInPostLikerProps> = ({
  initialPostURL = "",
  initialLinkedinCookies = "",
  handleClose,
  table_state,
  chat_id,
}) => {
  const { setSidebarOpen } = useStateContext();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [postURL, setPostURL] = useState(initialPostURL);
  const [sessionCookies, setSessionCookies] = useState(initialLinkedinCookies);
  const navigator = useNavigate();

  async function handleMessageSend() {
    try {
      setLoading(true);

      if (!user) {
        toast.error("User is not authenticated.");
        return;
      }

      if (!postURL || postURL.trim() === "") {
        toast.error("Please enter a valid LinkedIn post URL.");
        return;
      }

      const linkedInPostUrlPattern =
        /^https?:\/\/(?:www\.)?linkedin\.com\/posts\/.*$/;

      if (!linkedInPostUrlPattern.test(postURL)) {
        toast.error("Please enter a valid LinkedIn post URL.");
        setLoading(false);
        return;
      }

      if (!sessionCookies || sessionCookies.trim() === "") {
        toast.error("Please enter a session cookie.");
        return;
      }

      const requestBody: any = {
        post_url: postURL,
        linkedin_cookies: sessionCookies,
      };

      let response;

      if (table_state === TableState.ERROR) {
        response = await backend_services.fetchProtectedData(
          `/initial_lists/retry/postLikers/${chat_id}`,
          user.getIdToken(),
          undefined,
          "POST",
          requestBody,
        );

        if (response.ok) {
          const result = await response.json();
          toast.success(result.message || "Retry initiated successfully.");
          handleClose();
        } else {
          throw new Error(await response.text());
        }
      } else {
        response = await backend_services.fetchProtectedData(
          `/initial_lists/postLikers`,
          user.getIdToken(),
          undefined,
          "POST",
          requestBody,
        );

        if (response.ok) {
          const result = await response.json();
          if (result?.chat_id) {
            navigator(`/chat/${result.chat_id}`);
            toast.success(result.message || "Request submitted successfully.");
            handleClose();
            setSidebarOpen(false);
          } else {
            toast.error(result.detail || "An error occurred.");
          }
        } else {
          throw new Error(await response.text());
        }
      }
    } catch (error: any) {
      console.error("Error:", error.message || error);
      toast.error(error.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="fixed left-1/2 top-1/2 z-50 h-[650px] w-[700px] -translate-x-1/2 -translate-y-1/2 transform overflow-y-auto rounded-lg bg-white px-6 py-6 shadow-lg">
      {loading && <Spinner text="Searching..." onWhiteBackground />}
      <div className="flex flex-col gap-5">
        <div>
          <div>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <img
                  src="/linkedin_icon.png"
                  alt="LinkedIn Post"
                  className="h-12 w-12"
                />
                <div className="">
                  <h2 className="text-xl font-bold">LinkedIn Post Liker</h2>
                  <p className="text-sm">
                    Download the chrome extension to find your session cookie.
                  </p>
                </div>
              </div>

              <button
                className="col-end-7 justify-self-end text-gray-500 hover:text-gray-700"
                onClick={handleClose}
              >
                <CloseIcon />
              </button>
            </div>

            <div className="flex items-center justify-center gap-4 pt-6">
              <div className="flex flex-col items-center">
                <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                  <DownloadIcon className="h-6 w-6" />
                </div>
                <span className="text-sm">Install Extension</span>
              </div>
              <div className="h-[2px] w-8 bg-gray-300" />
              <div className="flex flex-col items-center">
                <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                  <CopyIcon className="h-6 w-6 text-gray-700" />
                </div>
                <span className="text-sm">Paste Cookie</span>
              </div>
              <div className="h-[2px] w-8 bg-gray-300" />
              <div className="flex flex-col items-center">
                <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                  <UserIcon className="h-6 w-6 text-gray-700" />
                </div>
                <span className="text-sm">Get Likes</span>
              </div>
            </div>
          </div>
          <LinkedInPostLikerForm
            postURL={postURL}
            setPostURL={setPostURL}
            sessionCookies={sessionCookies}
            setSessionCookies={setSessionCookies}
            onSubmit={handleMessageSend}
          />
        </div>
        <hr className="w-full" />
        <div className="pt-6">
          <h2 className="mb-4 text-sm font-bold">Example Output:</h2>
          <div className="overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="border-b">
                  <th className="px-4 py-3 text-left font-medium">Name</th>
                  <th className="px-4 py-3 text-left font-medium">
                    Profile Link
                  </th>
                  <th className="px-4 py-3 text-left font-medium">Post URL</th>
                  <th className="px-4 py-3 text-left font-medium">
                    Reaction type
                  </th>
                  <th className="px-4 py-3 text-left font-medium">Last Name</th>
                  <th className="px-4 py-3 text-left font-medium">
                    First Name
                  </th>
                  <th className="px-4 py-3 text-left font-medium">
                    Occupation
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b">
                  <td className="px-4 py-3">John Doe</td>
                  <td className="px-4 py-3">
                    <a
                      href="https://www.linkedin.com/in/johndoe"
                      className="text-blue-500"
                    >
                      Profile Link
                    </a>
                  </td>
                  <td className="px-4 py-3">
                    <a
                      href="https://www.linkedin.com/posts/123/comment-1"
                      className="text-blue-500"
                    >
                      Comment Link
                    </a>
                  </td>
                  <td className="px-4 py-3">Great insights!</td>
                  <td className="px-4 py-3">Doe</td>
                  <td className="px-4 py-3">John</td>
                  <td className="px-4 py-3">Software Engineer</td>
                </tr>
                <tr className="border-b">
                  <td className="px-4 py-3">Jane Doe</td>
                  <td className="px-4 py-3">
                    <a
                      href="https://www.linkedin.com/in/janedoe"
                      className="text-blue-500"
                    >
                      Profile Link
                    </a>
                  </td>
                  <td className="px-4 py-3">
                    <a
                      href="https://www.linkedin.com/posts/123/comment-2"
                      className="text-blue-500"
                    >
                      Comment Link
                    </a>
                  </td>
                  <td className="px-4 py-3">
                    Very helpful information, thanks!
                  </td>
                  <td className="px-4 py-3">Doe</td>
                  <td className="px-4 py-3">Jane</td>
                  <td className="px-4 py-3">Marketing Specialist</td>
                </tr>
                <tr className="border-b">
                  <td className="px-4 py-3">Alex Smith</td>
                  <td className="px-4 py-3">
                    <a
                      href="https://www.linkedin.com/in/alexsmith"
                      className="text-blue-500"
                    >
                      Profile Link
                    </a>
                  </td>
                  <td className="px-4 py-3">
                    <a
                      href="https://www.linkedin.com/posts/123/comment-3"
                      className="text-blue-500"
                    >
                      Comment Link
                    </a>
                  </td>
                  <td className="px-4 py-3">Thanks for sharing this!</td>
                  <td className="px-4 py-3">Smith</td>
                  <td className="px-4 py-3">Alex</td>
                  <td className="px-4 py-3">Data Scientist</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr className="w-full" />
        <InstallChromeExtension />
      </div>
    </div>
  );
};

export default LinkedInPostLiker;
