import { useState, useMemo, useEffect } from "react";
import { KurationTool } from "src/models/tools";
import SearchIcon from "../svgs/Search";

export function ToolsList({
  tools,
  setSelectedTool,
}: {
  tools: KurationTool[];
  setSelectedTool: React.Dispatch<React.SetStateAction<KurationTool | null>>;
}) {
  // State to hold the search query
  const [searchTerm, setSearchTerm] = useState("");

  // Check if the "who_is_remote" tool is loaded
  useEffect(() => {
    console.log(
      "Loaded tools:",
      tools.map((tool) => tool.id),
    ); // Log all tool IDs
  }, [tools]);

  // Filter tools based on search term
  const filteredTools = useMemo(() => {
    return Object.entries(categorizedToolIds).reduce(
      (acc, [category, toolIds]) => {
        // Filter tools by matching IDs from categorizedTools and ensuring they exist in tools
        const matchedTools = toolIds
          .map((id) =>
            tools.find(
              (tool) =>
                tool.id === id &&
                tool.name.toLowerCase().includes(searchTerm.toLowerCase()),
            ),
          )
          .filter(Boolean) as KurationTool[];

        if (matchedTools.length) {
          acc[category] = matchedTools;
        }
        return acc;
      },
      {} as Record<string, KurationTool[]>,
    );
  }, [searchTerm, tools]);

  // Render tool button
  const renderToolButton = (tool: KurationTool) => (
    <button
      key={tool.id}
      onClick={() => setSelectedTool(tool)}
      className="flex w-full cursor-pointer items-center gap-3 rounded p-4 outline-none transition-all duration-300 hover:bg-lightpurple focus-visible:bg-lightpurple"
    >
      <div>
        <img src={tool.icon} alt={`${tool.name} icon`} width={30} height={30} />
      </div>
      <div className="text-lg">{tool.name}</div>
    </button>
  );

  return (
    <div className="relative max-h-[70vh] overflow-auto py-4">
      {/* Sticky search bar */}
      <div className="sticky top-0 z-10 mb-4 bg-white py-2">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3">
          <SearchIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
        </div>
        <input
          type="search"
          id="default-search"
          className="block w-full rounded border p-2 pl-10 ps-10"
          placeholder="Search tools..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Render filtered tools under each category */}
      {Object.entries(filteredTools).map(([category, tools]) => (
        <div key={category} className="mb-6">
          <h2 className="mb-3 text-xl font-semibold">{category}</h2>
          {tools.map((tool) => renderToolButton(tool))}
        </div>
      ))}

      {/* If no tools found */}
      {Object.keys(filteredTools).length === 0 && (
        <div className="text-center text-gray-500">No tools found</div>
      )}
    </div>
  );
}

// Categorized tool IDs, organized by category
export const categorizedToolIds = {
  "Data Collection & Profile Enrichment": [
    "google_search",
    "baidu_search",
    "sleeper_tool",
    //"website_finder_runner",
    "find_company_official_website",
    "email_from_linkedin",
    "email_from_linkedin_contactout",
    //"phone_number_from_linkedin",
    "phone_number_from_linkedin_enrich",
    "phone_number_fetcher",
    "validate_phone_number",
    "linkedin_profile_details",
    "linkedin_profile_rapidapi",
    //c "linkedin_company_details",
    "linkedin_finder",
    "classify_linkedin_expat_status_model",
    "get_departmental_head_count",
    "headcount_fetcher_runner",
    // c "fetch_and_process_company_info",
    "get_company_industry",
    "detect_website_technologies",
    "detect_chatbot_technologies",
    //"builtwith_tech_inspector_info",
    "get_employee_location_breakdown",
    "extract_emails_from_website",
    //"domain_ad_history",
    "ads_detail_finder",
    "url_scraper",
  ],
  "Data Validation & Classification": [
    //"email_validator",
    "validate_email_abstractapi",
    "email_finder",
    "key_value_extractor",
    "validate_url",
    "classify_target_audience",
    "classify_website_service_model",
    "check_distributor_status",
    "check_hiring_status",
    "check_on_premise_infrastructure",
    "who_is_remote",
    "CRNumberLookup",
    "waterfall_logic",
  ],
  "Social Media & Review Analysis": [
    //c "get_company_trustpilot_url",
    //c "get_trustpilot_average_rating",
    "get_latest_trustpilot_reviews",
    "extract_twitter_from_domain",
    "extract_facebook_from_domain",
    "extract_linkedin_from_domain",
    "linkedin_website_finder",
    "linkedin_post_fetcher",
    "domain_parking_checker",
    "check_company_news",
    "check_company_jobs",
    "check_company_events",
    "predictleads_financing_events",
    "check_company_ma",
    //"fetch_traffic_report",
    "fetch_domain_traffic_similarweb",
    "marketing_campaigns_similarweb",
    "affiliate_partnerships_similarweb",
    "supercharge_seo_ppc_similarweb",
    "enrich_crm_similarweb",
  ],
  "Content & Messaging Enhancement": [
    "llm_utility_instruction",
    "llm_utility_instruction2",
    "llm_utility_instruction4",
    "llm_utility_instruction5",
    "lead_matching",
    "VisionModel",
    "categorize_lead",
    "screenshot_taker",
  ],
  "Revenue & Financial Analysis": [
    "revenue_stream_analystor",
    "get_annual_revenue",
    //"business_snapshot",
    "FundingData_validator",
  ],
  "Specific People Search and Counting": [
    "FolkFinder",
    //"email_counter",
    //"email_extractor",
  ],
};
