import React from "react";
import { countries } from "src/components/utils";
import SearchIcon from "src/components/svgs/Search";

interface LookALikeSearchFormProps {
  domain: string;
  setDomain: (value: string) => void;
  keyword: string;
  setKeyword: (value: string) => void;
  nlMatch: string;
  setNlMatch: (value: string) => void;
  maxRecords: number;
  setMaxRecords: (value: number) => void;
  country: string;
  setCountry: (value: string) => void;
  onSubmit: () => void;
}

const LookALikeSearchForm: React.FC<LookALikeSearchFormProps> = ({
  domain,
  setDomain,
  keyword,
  setKeyword,
  nlMatch,
  setNlMatch,
  maxRecords,
  setMaxRecords,
  country,
  setCountry,
  onSubmit,
}) => {
  return (
    <div className="relative grid gap-6">
      {/* Domain Input */}
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <p>Domain</p>
        </div>
        <textarea
          value={domain}
          placeholder={"Enter the domain (e.g., example.com)"}
          onChange={(e) => setDomain(e.target.value)}
          className="peer inline-flex max-h-14 w-full resize-none items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014] outline-none hover:border-purple md:pl-4"
        ></textarea>
      </div>

      {/* Keyword Input */}
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <p>Keyword</p>
        </div>
        <textarea
          value={keyword}
          placeholder={"Enter the keyword to find similar companies"}
          onChange={(e) => setKeyword(e.target.value)}
          className="peer inline-flex max-h-14 w-full resize-none items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014] outline-none hover:border-purple md:pl-4"
        ></textarea>
      </div>

      {/* Deep Learning Match Input */}
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <p>Describe the company's main services and industry</p>
        </div>
        <textarea
          value={nlMatch}
          placeholder={"I am searching for..."}
          onChange={(e) => setNlMatch(e.target.value)}
          className="peer inline-flex max-h-14 w-full resize-none items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014] outline-none hover:border-purple md:pl-4"
        ></textarea>
      </div>

      {/* Max Records Input */}
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <p>Max Records</p>
        </div>
        <input
          type="number"
          value={maxRecords}
          min={1}
          placeholder="Enter max number of records (e.g., 100)"
          onChange={(e) => setMaxRecords(Number(e.target.value))}
          className="peer inline-flex h-10 w-full items-center justify-center rounded-md border-2 border-neutral-400 bg-white px-4 shadow-[2px_2px_20px_0px_#00000014] outline-none hover:border-purple"
        />
      </div>

      {/* Country Input */}
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <p>Country</p>
        </div>
        <select
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          className="peer inline-flex h-10 w-full items-center justify-center rounded-md border-2 border-neutral-400 bg-white px-4 shadow-[2px_2px_20px_0px_#00000014] outline-none hover:border-purple"
        >
          <option value="">Select a country</option>
          {countries.map(({ code, name }) => (
            <option key={code} value={code}>
              {name}
            </option>
          ))}
        </select>
      </div>

      {/* Submit Button */}
      <div className="flex justify-between">
        <div className="items-center">1 credit per lookup</div>
        <button
          onClick={onSubmit}
          className="flex items-center gap-2 rounded bg-purple p-[7px] text-white outline-none hover:bg-purpleHover focus:bg-purpleHover"
        >
          <p>Fetch Companies</p>
          <div>
            <SearchIcon height="20" width="20" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default LookALikeSearchForm;
