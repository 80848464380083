import * as React from "react";
const KurationDustbin = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.125 2.125H6.875C6.875 1.89294 6.78281 1.67038 6.61872 1.50628C6.45462 1.34219 6.23206 1.25 6 1.25C5.76794 1.25 5.54538 1.34219 5.38128 1.50628C5.21719 1.67038 5.125 1.89294 5.125 2.125ZM4.25 2.125C4.25 1.66087 4.43437 1.21575 4.76256 0.887563C5.09075 0.559374 5.53587 0.375 6 0.375C6.46413 0.375 6.90925 0.559374 7.23744 0.887563C7.56563 1.21575 7.75 1.66087 7.75 2.125H11.25C11.366 2.125 11.4773 2.17109 11.5594 2.25314C11.6414 2.33519 11.6875 2.44647 11.6875 2.5625C11.6875 2.67853 11.6414 2.78981 11.5594 2.87186C11.4773 2.95391 11.366 3 11.25 3H10.7565L9.70212 10.7333C9.63061 11.2573 9.37164 11.7377 8.97316 12.0855C8.57468 12.4333 8.06367 12.625 7.53475 12.625H4.46525C3.93633 12.625 3.42532 12.4333 3.02684 12.0855C2.62835 11.7377 2.36939 11.2573 2.29788 10.7333L1.2435 3H0.75C0.633968 3 0.522688 2.95391 0.440641 2.87186C0.358594 2.78981 0.3125 2.67853 0.3125 2.5625C0.3125 2.44647 0.358594 2.33519 0.440641 2.25314C0.522688 2.17109 0.633968 2.125 0.75 2.125H4.25ZM5.125 5.1875C5.125 5.07147 5.07891 4.96019 4.99686 4.87814C4.91481 4.79609 4.80353 4.75 4.6875 4.75C4.57147 4.75 4.46019 4.79609 4.37814 4.87814C4.29609 4.96019 4.25 5.07147 4.25 5.1875V9.5625C4.25 9.67853 4.29609 9.78981 4.37814 9.87186C4.46019 9.95391 4.57147 10 4.6875 10C4.80353 10 4.91481 9.95391 4.99686 9.87186C5.07891 9.78981 5.125 9.67853 5.125 9.5625V5.1875ZM7.3125 4.75C7.42853 4.75 7.53981 4.79609 7.62186 4.87814C7.70391 4.96019 7.75 5.07147 7.75 5.1875V9.5625C7.75 9.67853 7.70391 9.78981 7.62186 9.87186C7.53981 9.95391 7.42853 10 7.3125 10C7.19647 10 7.08519 9.95391 7.00314 9.87186C6.92109 9.78981 6.875 9.67853 6.875 9.5625V5.1875C6.875 5.07147 6.92109 4.96019 7.00314 4.87814C7.08519 4.79609 7.19647 4.75 7.3125 4.75ZM3.165 10.6151C3.20794 10.9295 3.36331 11.2177 3.60236 11.4263C3.84141 11.635 4.14795 11.75 4.46525 11.75H7.53475C7.8522 11.7502 8.15895 11.6353 8.39818 11.4266C8.63741 11.218 8.79291 10.9297 8.83588 10.6151L9.87362 3H2.12637L3.165 10.6151Z"
      fill="#F85050"
    />
  </svg>
);

export default KurationDustbin;
