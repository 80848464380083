import * as React from "react";
const KurationCreditCard = ({ stroke = "#5733CF", ...props }) => (
  <svg
    {...props}
    stroke={stroke}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 12.5C2 8.729 2 6.843 3.172 5.672C4.343 4.5 6.229 4.5 10 4.5H14C17.771 4.5 19.657 4.5 20.828 5.672C22 6.843 22 8.729 22 12.5C22 16.271 22 18.157 20.828 19.328C19.657 20.5 17.771 20.5 14 20.5H10C6.229 20.5 4.343 20.5 3.172 19.328C2 18.157 2 16.271 2 12.5Z"
      stroke={stroke}
      strokeWidth="1.5"
    />
    <path
      d="M10 16.5H6M14 16.5H12.5M2 10.5H22"
      stroke={stroke}
      strokeWidth="1.5"
      stroke-linecap="round"
    />
  </svg>
);

export default KurationCreditCard;
