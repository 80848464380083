import {
  collection,
  getFirestore,
  query,
  orderBy,
  limit,
  DocumentSnapshot,
  onSnapshot,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";

import firebase_app from "src/firebase/config";
import { TableRow } from "src/models/data";
import { RowFilter } from "./useChatTable";

export function getFiltersQueries(row_filters: { [id: string]: RowFilter }) {
  if (Object.keys(row_filters).length === 0) {
    return [];
  }
  return [where("filter_state", "==", true)];
}
const useTableRows = ({
  tableId,
  pageSize,
  row_filters,
  filter_running_state_counter,
  chatId,
}: {
  tableId: string;
  pageSize: number;
  row_filters: { [id: string]: RowFilter };
  filter_running_state_counter: number;
  chatId: string;
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<TableRow[]>([]);
  const [page, setPage] = useState(1);


  const numPerPage = pageSize;
  function fetchPage() {
    // console.log("fetching TABLE ROWS");
    setLoading(true);

    const startIndex = (page - 1) * numPerPage;
    // const endIndex = page * numPerPage - 1;

    const collectionS = `/chats/${chatId}/tables/${tableId}/rows`;
    const dataCollection = collection(getFirestore(firebase_app), collectionS);
    // Define the data query with index range and limit
    const dataQuery = query(
      dataCollection,
      ...getFiltersQueries(row_filters),
      where("index", ">=", startIndex),
      // where("index", "<=", endIndex),
      orderBy("index"),
      limit(numPerPage)
    );

    const unsubscribe = onSnapshot(
      dataQuery,
      (snapshot) => {
        const allRows: TableRow[] = [];
        const allRowSnapshots: DocumentSnapshot[] = [];
        snapshot.forEach((row) => {
          allRowSnapshots.push(row);
          const rowData = { id: row.id, ...row.data() };

          allRows.push(new TableRow(rowData));
        });
        setLoading(false);
        setData(allRows);

      },
      (error) => {
        console.log(error);
        setLoading(false);
      },
    );
    return unsubscribe;
  }
  useEffect(() => {
    const unsubscribe = fetchPage();
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, tableId, page, filter_running_state_counter]);

  const sortedRowFilters = JSON.stringify(
    Object.keys(row_filters)
      .sort((a, b) => {
        return a.localeCompare(b);
      })
      .map((id) => {
        return { id };
      }),
  );

  useEffect(() => {
    // console.log("sortedRowFilters changed", sortedRowFilters, filter_running_state_counter);
    setPage(1);

  }, [sortedRowFilters, filter_running_state_counter, chatId, tableId]);

  function getNextPage() {
    setPage(page + 1);
  }
  function getPreviousPage() {
    setPage(page - 1);
  }
  return { allRows: data, loading, getNextPage, getPreviousPage, page, setPage };
};

export default useTableRows;
