import { useAuth } from "../../context/AuthContext";
import useUserCredits from "src/hooks/useUserCredits";
import useAllChats from "src/hooks/useAllChats";
import PricingToggle from "./pricing/PricingToggle";
import { useNavigate } from "react-router-dom";

export default function CreditPage() {
  const { user } = useAuth();

  const { userCredits } = useUserCredits(user?.uid ?? null);
  const { data: userChats } = useAllChats(user?.uid ?? null);
  const navigate = useNavigate();

  return (
    <div className="flex w-[534px] flex-col gap-y-[48px] bg-white">
      <div className="flex flex-col gap-y-2">
        <div className="text-5xl">Credit</div>
        <div className="text-2xl text-grey-100">
          Check usage and top-up your account
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col">
          <p className="text-2xl">Credits</p>
          <hr />
          <div className="flex items-center justify-between py-6">
            <div className="flex flex-col gap-y-4">
              <div className="text-4xl text-purple">
                {!!userCredits
                  ? `${Intl.NumberFormat("en", { notation: "compact" }).format(
                      userCredits.col_credits,
                    )}`
                  : "..."}{" "}
              </div>
              <div className="text-gray-400">Credits left</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-2xl">Lifetime Plan</p>
          <hr />
          <button
            onClick={() => navigate("/earlybird")}
            className="my-4 w-fit rounded bg-primary px-4 py-2 font-bold text-white transition-colors hover:bg-purpleHover"
          >
            Redeem Code
          </button>
        </div>
        <div className="flex flex-col py-2">
          <p className="text-2xl">Pricing</p>
          <hr />
          <div className="py-6">
            <PricingToggle />
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-2xl">Usage History</p>
          <hr />
        </div>
        <div className="flex flex-col justify-start py-2">
          <div className="flex py-6">
            <div className="flex items-center gap-x-4">
              <div className="text-4xl">{userChats?.length}</div>
              <div className="text-gray-400">Total chats</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-2xl">Details</p>
          <hr />
        </div>
        <div className="flex gap-2">
          <button
            onClick={() =>
              window.open("https://www.kurationai.com/company/privacy-policy")
            }
            className="my-4 w-fit rounded bg-primary px-4 py-2 font-bold text-white transition-colors hover:bg-purpleHover"
          >
            Privacy Policy
          </button>
          <button
            onClick={() =>
              window.open("https://www.kurationai.com/terms-conditions")
            }
            className="my-4 w-fit rounded bg-primary px-4 py-2 font-bold text-white transition-colors hover:bg-purpleHover"
          >
            Terms and Conditions
          </button>
        </div>
      </div>
    </div>
  );
}
