import * as React from "react";
const KurationLinkedinIcon = ({ stroke = "#5733CF", ...props }) => (
  <svg
    viewBox="0 0 24 25"
    stroke={stroke}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.002 10.05C12.919 9.113 14.113 8.5 15.502 8.5C16.9606 8.5 18.3596 9.07946 19.391 10.1109C20.4225 11.1424 21.002 12.5413 21.002 14V21.5H19.002V14C19.002 13.0717 18.6332 12.1815 17.9768 11.5251C17.3204 10.8687 16.4302 10.5 15.502 10.5C14.5737 10.5 13.6835 10.8687 13.0271 11.5251C12.3707 12.1815 12.002 13.0717 12.002 14V21.5H10.002V9H12.002V10.05ZM5.00195 7C4.60413 7 4.2226 6.84196 3.94129 6.56066C3.65999 6.27936 3.50195 5.89782 3.50195 5.5C3.50195 5.10218 3.65999 4.72064 3.94129 4.43934C4.2226 4.15804 4.60413 4 5.00195 4C5.39978 4 5.78131 4.15804 6.06261 4.43934C6.34392 4.72064 6.50195 5.10218 6.50195 5.5C6.50195 5.89782 6.34392 6.27936 6.06261 6.56066C5.78131 6.84196 5.39978 7 5.00195 7ZM4.00195 9H6.00195V21.5H4.00195V9Z"
      fill="#705CF6"
    />
  </svg>
);

export default KurationLinkedinIcon;
