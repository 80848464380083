import * as React from "react";
const KurationAmount = ({ fill = "#5733CF", ...props }) => (
  <svg
    viewBox="0 0 24 25"
    fill={fill}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00043 8.75043C8.81043 8.75043 8.62043 8.68043 8.47043 8.53043L6.50043 6.56043L4.53043 8.53043C4.24043 8.82043 3.76043 8.82043 3.47043 8.53043C3.18043 8.24043 3.18043 7.76043 3.47043 7.47043L5.97043 4.97043C6.26043 4.68043 6.74043 4.68043 7.03043 4.97043L9.53043 7.47043C9.82043 7.76043 9.82043 8.24043 9.53043 8.53043C9.38043 8.68043 9.19043 8.75043 9.00043 8.75043Z"
      fill={fill}
    />
    <path
      d="M6.5 20.25C6.09 20.25 5.75 19.91 5.75 19.5V5.5C5.75 5.09 6.09 4.75 6.5 4.75C6.91 4.75 7.25 5.09 7.25 5.5V19.5C7.25 19.91 6.91 20.25 6.5 20.25ZM20 17.75H12C11.59 17.75 11.25 17.41 11.25 17C11.25 16.59 11.59 16.25 12 16.25H20C20.41 16.25 20.75 16.59 20.75 17C20.75 17.41 20.41 17.75 20 17.75ZM16 11.75H12C11.59 11.75 11.25 11.41 11.25 11C11.25 10.59 11.59 10.25 12 10.25H16C16.41 10.25 16.75 10.59 16.75 11C16.75 11.41 16.41 11.75 16 11.75ZM14 8.75H12C11.59 8.75 11.25 8.41 11.25 8C11.25 7.59 11.59 7.25 12 7.25H14C14.41 7.25 14.75 7.59 14.75 8C14.75 8.41 14.41 8.75 14 8.75ZM18 14.75H12C11.59 14.75 11.25 14.41 11.25 14C11.25 13.59 11.59 13.25 12 13.25H18C18.41 13.25 18.75 13.59 18.75 14C18.75 14.41 18.41 14.75 18 14.75Z"
      fill={fill}
    />
  </svg>
);
export default KurationAmount;
