import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import CustomDropdown from "../../components/elements/CustomDropDownMenu";
import { ProfilePicture } from "../../components/elements/ProfilePicture";
import SettingIcon from "../../components/svgs/Setting";
import { SignOutIcon } from "../../components/svgs/SignOutIcon";
import { IconItem } from "./ChatNameBox";
import ProfileIcon from "../../components/svgs/ProfileIcon";

export default function ProfileCard() {
  const { user, signOut } = useAuth();
  const navigator = useNavigate();

  return (
    <div>
      <div className="flex flex-col rounded-2xl px-2.5 py-2 focus-within:bg-lightpurple hover:bg-lightpurple">
        {user?.isAnonymous === false ? (
          <>
            <div className="group/profile flex items-center justify-start gap-2 ">
              <Link
                to={"/profile/account"}
                className="flex items-center justify-center"
              >
                <ProfilePicture dimensions={48} shadow={false} />
                <div className="w-[104px] overflow-hidden text-ellipsis text-xl font-normal text-black">
                  {user?.displayName || user?.email}
                </div>
              </Link>
              <CustomDropdown
                opener={
                  <button className="flex items-center justify-center rounded bg-white pb-[6px] pl-[6px] pr-[5px] pt-[5px] opacity-0 focus:opacity-100 group-hover/profile:opacity-100">
                    <SettingIcon />
                  </button>
                }
                options={[
                  <Link to={"/profile/account"}>
                    <IconItem
                      onClick={() => {}}
                      text="View profile"
                      icon={<ProfileIcon />}
                    />
                  </Link>,
                  <IconItem
                    onClick={() => {
                      signOut();
                      navigator("/login");
                    }}
                    text="Logout"
                    icon={<SignOutIcon />}
                    overrideClasses="text-error"
                  />,
                ]}
              />
            </div>
            <div className="mt-0">
              <button
                onClick={() =>
                  window.open(
                    "https://www.kurationai.com/company/privacy-policy",
                  )
                }
                className="text-sm text-black hover:underline"
              >
                Privacy policy
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col gap-2 p-3">
              <Link
                to="/login"
                className="rounded-md bg-primary p-2 text-center text-white hover:bg-purpleHover"
              >
                Login
              </Link>
              <Link
                to="/signup"
                className="rounded-md bg-primary p-2 text-center text-white hover:bg-purpleHover"
              >
                Signup
              </Link>
              <div className="mt-0 flex items-center justify-center">
                <button
                  onClick={() =>
                    window.open(
                      "https://www.kurationai.com/company/privacy-policy",
                    )
                  }
                  className="text-sm text-black hover:underline"
                >
                  Privacy policy
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
