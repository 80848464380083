import { useState, useMemo } from "react";
import { categorizedToolIds } from "src/components/columnAdder/ToolsList";
import { useQuery } from "@tanstack/react-query";
import { fetchTools } from "src/components/utils";
import { useAuth } from "src/context/AuthContext";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import CoinIcon from "src/components/svgs/Coin";
import { KurationTool } from "src/models/tools";
import KurationGridView from "src/components/svgs/KurationGridView";
import KurationLinkedinIcon from "src/components/svgs/KurationLinkedinIcon";
import KurationAmount from "src/components/svgs/KurationAmount";
import KurationTable from "src/components/svgs/KurationTable";
import KurationCreditCard from "src/components/svgs/KurationCreditCard";
import KurationPeople from "src/components/svgs/KurationPeople";
import { SimpleSpinner } from "src/components/elements/Spinner";

const KurationCheckTools = () => {
  const { user } = useAuth();
  const [activeCategory, setActiveCategory] = useState("Show All");
  const [searchTerm, setSearchTerm] = useState("");
  const [hoveredTool, setHoveredTool] = useState<string>("");
  const { data: tools, isLoading } = useQuery({
    queryKey: ["tools"],
    refetchOnWindowFocus: false,
    queryFn: () => user && fetchTools(user?.getIdToken()),
  });

  // Define category icons mapping with dynamic color based on active state
  const getCategoryIcon = (category: string, isActive: boolean) => {
    switch (category) {
      case "Show All":
        return (
          <KurationGridView
            stroke={isActive ? "#FFFFFF" : "#5733CF"}
            className="h-4 w-4"
          />
        );
      case "Data Collection & Profile Enrichment":
        return (
          <KurationTable
            stroke={isActive ? "#FFFFFF" : "#5733CF"}
            className="h-4 w-4"
          />
        );
      case "Data Validation & Classification":
        return (
          <KurationAmount
            fill={isActive ? "#FFFFFF" : "#5733CF"}
            className="h-4 w-4"
          />
        );

      case "Content & Messaging Enhancement":
        return (
          <KurationLinkedinIcon
            className="h-4 w-4"
            stroke={isActive ? "#FFFFFF" : "#5733CF"}
          />
        );
      case "Revenue & Financial Analysis":
        return (
          <KurationCreditCard
            className="h-4 w-4"
            stroke={isActive ? "#FFFFFF" : "#5733CF"}
          />
        );
      case "Specific People Search and Counting":
        return (
          <KurationPeople
            className="h-4 w-4"
            stroke={isActive ? "#FFFFFF" : "#5733CF"}
          />
        );
      default:
        return (
          <KurationGridView
            stroke={isActive ? "white" : "#6B7280"}
            className="h-4 w-4"
          />
        );
    }
  };

  const filteredTools = useMemo(() => {
    return Object.entries(categorizedToolIds).reduce(
      (acc, [category, toolIds]) => {
        // Skip categories that don't match the active category (unless "Show All" is selected)
        if (activeCategory !== "Show All" && category !== activeCategory) {
          return acc;
        }

        // Filter tools by matching IDs from categorizedTools and ensuring they exist in tools
        const matchedTools = toolIds
          .map((id) =>
            tools?.find(
              (tool) =>
                tool.id === id &&
                tool.name.toLowerCase().includes(searchTerm.toLowerCase()),
            ),
          )
          .filter(Boolean) as KurationTool[];

        if (matchedTools.length) {
          acc[category] = matchedTools;
        }
        return acc;
      },
      {} as Record<string, KurationTool[]>,
    );
  }, [searchTerm, tools, activeCategory]);

  const categories = ["Show All", ...Object.keys(categorizedToolIds)];

  // Split categories into rows of 4
  const categoriesFirstRow = categories.slice(0, 3);
  const categoriesSecondRow = categories.slice(4);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center pt-20">
        <SimpleSpinner radius={30} overrideClasses="border-primary" />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <p className="text-3xl">Check our Tools</p>
      <p className="mt-2 text-lightgray">
        Discover innovative solutions designed to streamline your processes and
        achieve your goals effortlessly.
      </p>
      <div className="relative mt-6 w-[868px]">
        <MagnifyingGlassIcon className="absolute left-4 top-1/2 h-5 w-5 -translate-y-1/2 text-primary" />
        <input
          type="text"
          placeholder="Search tools"
          className="h-[52px] w-full rounded-md border border-[#D1D1D1] pl-12 pr-4 text-gray-700 placeholder-gray-400 placeholder:pl-2 focus:outline-none focus:ring-0"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* First row of category buttons */}
      <div className="mt-6 grid w-full max-w-4xl grid-cols-3 gap-3">
        {categoriesFirstRow.map((category) => (
          <div
            key={category}
            onClick={() => setActiveCategory(category)}
            className={`flex cursor-pointer items-center justify-center gap-1 rounded-3xl px-3 py-2 ${
              activeCategory === category
                ? "bg-purple"
                : "border border-[#DFE1FF] bg-[#DFE1FF] bg-opacity-[30%]"
            }`}
          >
            {getCategoryIcon(category, activeCategory === category)}
            <p
              className={`text-sm ${
                activeCategory === category ? "text-white" : "text-[#676D7E]"
              }`}
            >
              {category}
            </p>
          </div>
        ))}
      </div>

      {/* Second row of category buttons - centered */}
      {categoriesSecondRow.length > 0 && (
        <div className="mt-3 flex w-full max-w-4xl justify-center">
          <div
            className={`grid grid-cols-${Math.min(categoriesSecondRow.length, 4)} gap-3`}
          >
            {categoriesSecondRow.map((category) => (
              <div
                key={category}
                onClick={() => setActiveCategory(category)}
                className={`flex cursor-pointer items-center justify-center gap-1 rounded-3xl px-3 py-2 ${
                  activeCategory === category
                    ? "bg-purple"
                    : "border border-[#DFE1FF] bg-[#DFE1FF] bg-opacity-[30%]"
                }`}
              >
                {getCategoryIcon(category, activeCategory === category)}
                <p
                  className={`text-sm ${
                    activeCategory === category
                      ? "text-white"
                      : "text-[#676D7E]"
                  }`}
                >
                  {category}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="mt-8">
        {Object.entries(filteredTools).map(([category, tools]) => (
          <div key={category}>
            <h2 className="py-5 text-2xl">{category}</h2>
            <div className="grid grid-cols-3 gap-4">
              {tools?.map((tool) => (
                <div
                  key={tool.id}
                  onMouseEnter={() => setHoveredTool(tool?.id)}
                  onMouseLeave={() => setHoveredTool("")}
                  className={`
             transform
            rounded-2xl border 
            border-lightpurple 
            bg-lightpurple 
            bg-opacity-[20%] 
            p-4 transition-all
            duration-300
            ease-in-out
            ${hoveredTool === tool.id ? "h-[250px]" : "h-[165px]"}
          `}
                >
                  <div className="rounded-2xl bg-white p-4">
                    <div className="flex justify-between">
                      <div>
                        <p className="text-sm text-lightgray">
                          Sources supported
                        </p>
                        <div className="mt-1 flex gap-1">
                          {tool.sources.map((source, idx) => (
                            <img
                              key={idx}
                              src={source?.logo}
                              alt={`${source?.name}-img`}
                              className="h-4 w-4"
                            />
                          ))}
                        </div>
                      </div>
                      <div className="flex h-min items-center gap-1 rounded-md bg-[#E1FFDE] px-2 py-[2px]">
                        <p className="text-[#32CB61]">
                          {tool.cost === 0 ? "Free" : tool.cost}
                        </p>
                        <CoinIcon />
                      </div>
                    </div>
                    <div className="mt-4 flex items-center gap-4">
                      <div className="flex h-10 w-10 items-center justify-center rounded-xl border border-[#E7E7E7]">
                        <img
                          className="h-4 w-4 object-contain"
                          src={tool.icon}
                          alt={`${tool.icon}-img`}
                        />
                      </div>
                      <p>{tool.name}</p>
                    </div>
                  </div>

                  {hoveredTool === tool.id && (
                    <div className="flex flex-col p-4">
                      <div className="flex items-center justify-between">
                        <p className="text-sm text-[#676D7E]">Input</p>
                        <p className="rounded-full border bg-lightpurple px-[8px] py-[4px] text-sm text-purpleHover">
                          {tool.short_input}
                        </p>
                      </div>
                      <div className="my-2 border-b border-lightpurple"></div>
                      <div className="flex items-center justify-between">
                        <p className="text-sm text-[#676D7E]">Output</p>
                        <p className="rounded-full border bg-lightpurple px-[8px] py-[4px] text-sm text-purpleHover">
                          {tool.short_output}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KurationCheckTools;
