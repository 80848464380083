import {
  getColumnDisplayName,
  sentenceCase,
  sentenceCaseShort,
} from "../utils";
import { useState, useEffect, useCallback } from "react";
import { IconButton } from "../elements/IconButton";
import StarsIcon from "../svgs/Stars";
import backend_services from "src/services/backend_service";
import { toast } from "react-toastify";
import { MultiValue } from "react-select";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useAuth } from "src/context/AuthContext";
import { useParams } from "react-router-dom";
import { SimpleSpinner } from "../elements/Spinner";
import useDefaultTable, {
  Column,
  KurationToolColumn,
} from "src/hooks/useChatTable";
import useChatDetails from "src/hooks/useChatDetails";
import { twMerge } from "tailwind-merge";
import { KurationTool } from "src/models/tools";
import { ColumnsTextArea } from "src/pages/filters/ColumnsTextArea";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { useStateContext } from "src/context/StateContext";
import { useSearchParams } from "react-router-dom";
import {
  FilterFields,
  FilterFormField,
  getUpdatedForm,
} from "../preCuratedLists/FilterFields";
import PlusIcon from "../svgs/Plus";
import { Company, FreeDict, TableRow } from "src/models/data";
import { parseFilterForm } from "src/pages/filters/filterUtils";
import { useMutation } from "@tanstack/react-query";
import { getPaginatedData } from "src/firebase/utils";

const hasLength = (arr: any[]): boolean => Array.isArray(arr) && arr.length > 0;

type OptionType = {
  value: string;
  label: string;
};

export function KurationColumnCreateEditForm({
  selectedTool,
  columnsFallback,
  onSuccess,
  preventColumnCreation,
  editColumn,
  companies,
}: {
  selectedTool: KurationTool;
  columnsFallback: Column[];
  onSuccess: ({
    tool,
    args,
    conditions,
  }: {
    tool: KurationTool;
    args: FreeDict;
    conditions: FreeDict[];
  }) => void;
  preventColumnCreation?: boolean;
  editColumn?: KurationToolColumn;
  companies?: Company[];
}) {
  const { chatId } = useParams();
  const { chatDetails, chatDetailsLoading } = useChatDetails(chatId ?? null);

  const tableId = chatDetails?.table_id;

  const { data: defaultTable } = useDefaultTable(tableId);
  const columns = defaultTable?.column_list || columnsFallback;
  const [conditionalColumn, setConditionalColumn] = useState(
    !!editColumn?.conditions?.length || false,
  );
  const [columnForm, setColumnForm] = useState<{
    [key: string]: string | null;
  }>(
    selectedTool.fields.reduce(
      (acc, f) => ({ ...acc, [f.id]: editColumn?.column_data?.[f.id] || null }),
      {},
    ),
  );

  const [selectedJsonKeyOptions, setSelectedJsonKeyOptions] = useState<
    string[]
  >([]);
  const [errors, setErrors] = useState<string[]>([]);
  const { user } = useAuth();

  const [addColumnLoading, setAddColumnLoading] = useState(false);
  const [filterForm, setFilterForm] = useState<FilterFormField[]>(
    editColumn?.conditions?.length ? editColumn.conditions : [],
  );
  const aiFormFillingMutation = useMutation({
    mutationKey: ["aiFormFilling", chatId, selectedTool.id],
    mutationFn: async () => {
      const response = await backend_services.fetchProtectedData(
        `/chat/${chatId}/table/tool/${selectedTool.id}/fill_form`,
        user?.getIdToken(),
        undefined,
        "POST",
        {
          column_list: columns,
        },
      );
      if (response.status !== 200) {
        throw new Error("Failed to fetch form data");
      }
      return response.json();
    },
    onSuccess: (data) => {
      console.log("AI Form Filling Mutation Success:", data);
      if ((data.status === "success", data.form)) {
        setColumnForm((columnForm) => ({
          ...columnForm,
          ...data.form,
        }));
      }
    },
  });

  useEffect(() => {
    console.log(
      "AI Form Filling Mutation Status:",
      aiFormFillingMutation.status,
    );
    if (editColumn) {
      return;
    }
    aiFormFillingMutation.mutate();
    // eslint-disable-next-line
  }, []);

  async function add_column(args: {
    [key: string]: string | null | Array<FreeDict>;
  }) {
    if (!user || !selectedTool) {
      return;
    }

    const newErrors: string[] = [];

    selectedTool.fields.forEach((f) => {
      const fieldValue = args[f.id]; // Access the field value using its `id` in args

      // If the field is required (optional is false) and missing a value, add it to errors
      if (!fieldValue && !f.optional) {
        newErrors.push(f.id);
      }
    });

    // Update error state
    setErrors(Array.from(new Set(newErrors)));

    if (newErrors.length > 0) return;

    const columnConditions = parseFilterForm(
      filterForm,
      defaultTable?.column_list || [],
    );
    setAddColumnLoading(true);
    if (preventColumnCreation) {
      setAddColumnLoading(false);
      onSuccess({ tool: selectedTool, args, conditions: columnConditions });
      return;
    }
    const url = editColumn
      ? `/chat/${chatId}/column/${editColumn.id}/kuration/update`
      : `/chat/${chatId}/column/${selectedTool.id}`;
    await backend_services
      .fetchProtectedData(url, user?.getIdToken(), undefined, "POST", {
        args,
        column_conditions: columnConditions,
      })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("invalid result");
        }

        toast.success(
          editColumn
            ? "Column updated."
            : "New column added at the end of table.",
        );
        onSuccess({ tool: selectedTool, args, conditions: columnConditions });
        return res.json();
      })
      .catch((err) => {
        toast.error(
          editColumn
            ? "Failed to update column. Please try again."
            : "Failed to create column. Please try again.",
        );
        setAddColumnLoading(false);
      });
    setAddColumnLoading(false);
  }

  const handleSubmit = () => {
    // Create a new object where unfilled fields are set to an empty string
    const updatedArgs = Object.fromEntries(
      Object.entries(columnForm).map(([key, value]) => [key, value ?? ""]),
    );

    // 1. If this is the key_value_extractor + tagSelect scenario and you have multiple keys, do multiple requests
    const isKeyValueExtractor = selectedTool.id === "key_value_extractor";
    const hasTagSelectValues = selectedJsonKeyOptions.length > 0;
    const tagSelectField = selectedTool.fields.find(
      (f) => f.fieldType === "tagSelect",
    );

    if (isKeyValueExtractor && tagSelectField && hasTagSelectValues) {
      // For each selected key, override the tagSelectField in updatedArgs, then call add_column
      selectedJsonKeyOptions.forEach((key) => {
        // Build request body
        const body = {
          ...updatedArgs,
          [tagSelectField.id]: key, // e.g. "json_key_select": "Open_to_Work"
        };
        add_column(body);
      });
    } else {
      // Otherwise, do the normal single request
      add_column(updatedArgs);
    }
  };
  if (addColumnLoading || chatDetailsLoading || !chatDetails) {
    return (
      <div className="flex h-full items-center justify-center gap-2 py-5">
        <SimpleSpinner radius={20} overrideClasses="border-black" />
        loading..
      </div>
    );
  }
  return (
    <div>
      <div className="mt-6 flex flex-col gap-4">
        {!editColumn && (
          <div className="flex flex-row items-center gap-1 opacity-50 transition-all duration-300 hover:opacity-100">
            <StarsIcon />
            {aiFormFillingMutation.status === "pending" ? (
              <>
                <div className="flex flex-row items-center gap-2">
                  AI is filling the form for you...
                </div>
                <SimpleSpinner radius={20} overrideClasses="border-black" />
              </>
            ) : (
              <div className="flex items-center gap-1">
                <div className="flex items-center gap-2">
                  Form filled by AI...
                </div>
                <IconButton
                  onClick={() => aiFormFillingMutation.mutate()}
                  hideIcon={true}
                  overrideClasses="max-h-min max-w-min border-none px-1 py-2"
                  text="Retry"
                  icon={<></>}
                  variant="outline"
                />
              </div>
            )}
          </div>
        )}
        <KurationColumnFormFields
          selectedTool={selectedTool}
          columnForm={columnForm}
          setColumnForm={setColumnForm}
          errors={errors}
          setErrors={setErrors}
          columns={columns}
          companies={companies}
          chatId={chatId}
          tableId={tableId || ""}
          onSelectedJsonKeysChange={setSelectedJsonKeyOptions}
        />
        <div className="mt-12">
          <ColumnConditionAdder
            key={"conditions"}
            columns={defaultTable?.column_list || []}
            conditionalColumn={conditionalColumn}
            filterForm={filterForm}
            setConditionalColumn={setConditionalColumn}
            setFilterForm={setFilterForm}
          />
        </div>
      </div>

      <IconButton
        overrideClasses="mt-4 h-9 gap-1.5 border-purple text-white  md:h-[42px]  "
        onClick={handleSubmit}
        variant="fill"
        icon={<StarsIcon className="h-4 w-4 md:h-6 md:w-6" fill="white" />}
        text={
          <div className="min-w-max text-sm  md:text-lg ">
            {editColumn ? "Update" : "Create"} Column
          </div>
        }
      />
    </div>
  );
}
export const KurationColumnFormFields = ({
  selectedTool,
  columnForm,
  setColumnForm,
  errors,
  setErrors,
  columns,
  companies,
  chatId,
  tableId,
  onSelectedJsonKeysChange,
}: {
  selectedTool: KurationTool;
  tableId: string;
  columnForm: {
    [key: string]: string | null;
  };
  setColumnForm: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string | null;
    }>
  >;
  errors: string[];
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  columns: Column[];
  companies?: Company[];
  chatId?: string;
  onSelectedJsonKeysChange?: (values: string[]) => void;
}) => {
  const { user } = useAuth();
  const [originalId, setOriginalId] = useState<string | null>(null);
  const [jsonKeyOptions, setJsonKeyOptions] = useState<string[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [testTableValues, setTestTableValues] = useState<string>("");
  const [testEmailContent, setTestEmailContent] = useState<string>("");
  const [testResultOutput, setTestResultOutput] = useState<string>("");
  // eslint-disable-next-line
  const [valueInputs, setValueInputs] = useState<Record<string, string>>({});
  const [isFetching, setIsFetching] = useState(false);
  const [companiesForJsonKeys, setCompaniesForJsonKeys] = useState({
    shouldLoad: true,
    companies: companies || [],
  });

  useEffect(
    () => {
      console.log({ companiesForJsonKeys });
      const getFirstPage = async () => {
        if (
          !companiesForJsonKeys.shouldLoad ||
          selectedTool.id !== "key_value_extractor"
        ) {
          console.log("SKIPPING LOADING FIRST PAGE");
          return;
        }
        setCompaniesForJsonKeys((prev) => ({ ...prev, shouldLoad: false }));
        if (!tableId) {
          console.log("NO TABLE ID")
          return;
        }
        const collection = `/chats/${chatId}/tables/${tableId}/rows`;
        
        getPaginatedData(collection, "index", {}, "next").then((result) => {
          const c = result.result
            .map((row) => new TableRow(row))
            .map((c) => c.company);
          setCompaniesForJsonKeys({ shouldLoad: false, companies: c });
        });
        console.warn("Missing required parameters for fetching JSON keys");
      };
      console.log("SHOULD LOADD?", companiesForJsonKeys.shouldLoad);
      if (companiesForJsonKeys.shouldLoad) {
        getFirstPage();
        return;
      }
    },
    // eslint-disable-next-line
    []
  );

  const fetchJsonKeyOptions = useCallback(() => {
    if (companiesForJsonKeys.companies.length < 1) {
      console.warn("Missing required parameters for fetching JSON keys");
      return;
    }

    const toastId = toast.loading("Fetching JSON keys...");
    setIsFetching(true);

    try {
      const matchingItems = companiesForJsonKeys.companies.flatMap((record) =>
        record.filter(
          (item: { custom_column_id: string }) =>
            item.custom_column_id === originalId,
        ),
      );

      if (matchingItems.length > 0) {
        const keys = matchingItems.flatMap((item) => {
          try {
            const value = item?.value;

            // If the value is a JSON string, parse it
            const jsonValue =
              typeof value === "string" ? JSON.parse(value) : value;

            // Extract only the root-level (top-level) keys from objects
            // or from objects within arrays
            const extractRootKeys = (val: any): string[] => {
              // If it's an array, collect unique keys from each object in the array
              if (Array.isArray(val)) {
                let allKeys: string[] = [];
                val.forEach((element) => {
                  if (element && typeof element === "object") {
                    const objectKeys = Object.keys(element).filter(
                      (key) => isNaN(Number(key)), // remove numeric indices
                    );
                    allKeys = [...allKeys, ...objectKeys];
                  }
                });
                return Array.from(new Set(allKeys));
              }
              // If it's a non-null object, extract top-level keys
              else if (val && typeof val === "object") {
                return Object.keys(val).filter((key) => isNaN(Number(key)));
              }
              // If it's neither array nor object, return an empty array
              return [];
            };

            return extractRootKeys(jsonValue);
          } catch (error) {
            console.warn("Failed to parse value as JSON:", item.value, error);
            return [];
          }
        });

        // Flatten and deduplicate all keys across all items
        const uniqueKeys = Array.from(new Set(keys));

        if (uniqueKeys.length > 0) {
          setJsonKeyOptions(uniqueKeys);
          toast.update(toastId, {
            render: "JSON keys fetched successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        } else {
          setJsonKeyOptions([]);
          toast.update(toastId, {
            render: "No JSON keys found in the selected row",
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
        }
      } else {
        console.error("No matching custom_column_id found for:", originalId);
        setJsonKeyOptions([]);
        toast.update(toastId, {
          render: "Please select a row containing JSON",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error processing JSON keys:", error);
      setJsonKeyOptions([]);
      toast.update(toastId, {
        render: "Error processing JSON keys",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      setIsFetching(false);
    }
    // eslint-disable-next-line
  }, [companies, originalId]);

  const handleColumnChange = (selectedValue: string, fId: string) => {
    setErrors((errs) => errs.filter((err) => err !== fId));
    setColumnForm((columnForm) => ({
      ...columnForm,
      [fId]: selectedValue,
    }));

    if (fId.startsWith("key_column")) {
      console.log("Selected key column:", selectedValue);
      const selectedColumn = columns.find(
        (col) => col.data_field === selectedValue,
      );

      if (selectedColumn) {
        setOriginalId(selectedColumn.id);
      }
    }
  };

  useEffect(() => {
    if (originalId) {
      fetchJsonKeyOptions();
    }
  }, [originalId, fetchJsonKeyOptions]);

  // Extract relevant fields for initialization in useEffect (this is needed if the user sends all the default fields)
  useEffect(() => {
    selectedTool.fields.forEach((f) => {
      if (f.fieldType === "tagSelect") {
        const optionList: OptionType[] = f.options.map((value) => ({
          value,
          label: value,
        }));

        const initialValue = optionList
          .map((option) => option.value)
          .join(", ");
        setColumnForm((columnForm) => ({
          ...columnForm,
          [f.id]: initialValue,
        }));

        setErrors((errs) => errs.filter((e) => e !== f.id));
      }
    });
  }, [selectedTool.fields, setColumnForm, setErrors]);

  interface TestCategorizeLeadPayload {
    categorize_lead: string;
    email_content: string;
  }

  const handleTestCategorizeLead = async (
    categorizeLead: string,
    emailContent: string,
  ) => {
    if (!user) {
      console.error("User is not authenticated.");
      return;
    }

    try {
      const payload: TestCategorizeLeadPayload = {
        categorize_lead: categorizeLead,
        email_content: emailContent,
      };

      const result = await toast.promise(
        backend_services.fetchProtectedData(
          `/test_categorize_lead/${chatId}`,
          user.getIdToken(),
          undefined,
          "POST",
          payload,
        ),
        {
          pending: "Processing the lead categorization...",
          success: "Lead categorization successful!",
          error: "Failed to categorize the lead. Please try again.",
        },
      );

      const response = await result.json();

      setTestResultOutput(response?.result);
    } catch (error) {
      console.error("Error categorizing lead:", error);
    }
  };

  function getValuesForField(
    allData: Company[],
    fieldName: string,
    maxCount = 10,
  ): string {
    const results: string[] = [];

    for (const row of allData) {
      for (const item of row) {
        if (item.data_field.toLowerCase() === fieldName.toLowerCase()) {
          results.push(String(item.value));
        }
        if (results.length >= maxCount) break;
      }
      if (results.length >= maxCount) break;
    }

    if (results.length === 0) {
      return "No values found";
    }

    return results.join("\n");
  }

  return (
    <>
      {selectedTool.fields.map((f) => {
        if (!Object.hasOwn(columnForm, f.id)) {
          setColumnForm((columnForm) => ({
            ...columnForm,
            [f.id]: null,
          }));
        }
        if (selectedTool.id === "key_value_extractor") {
          if (f.fieldType === "keySelect") {
            // Handle key select field
            const options =
              f.id === "json_key_select"
                ? jsonKeyOptions
                : f.options === "__COLUMN__"
                  ? columns
                  : f.options;

            const isJsonKeySelect = f.id === "json_key_select";
            const hasOptions = hasLength(options);

            if (isJsonKeySelect && !hasOptions) {
              return null;
            }

            return (
              <div className="flex flex-col gap-2" key={f.id}>
                <label
                  htmlFor={f.id}
                  className="block pl-1 font-medium text-gray-900"
                >
                  {f.label}
                </label>
                <select
                  id={f.id}
                  value={columnForm[f.id] || ""}
                  onChange={(e) => {
                    console.log(e);
                    handleColumnChange(e.target.value, f.id);
                  }}
                  className={twMerge(
                    "block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500",
                    errors.includes(f.id) ? "border-red-600" : "",
                  )}
                >
                  <option value="">Not selected</option>
                  {options.map((o) => {
                    const currOption =
                      typeof o === "string"
                        ? { id: o, display: o, originalId: o }
                        : {
                            id: o.data_field,
                            originalId: o.id,
                            display: getColumnDisplayName(o),
                          };

                    return (
                      <option value={currOption.id} key={currOption.id}>
                        {sentenceCase(currOption.display, "_")}
                      </option>
                    );
                  })}
                </select>
                {isJsonKeySelect && isFetching && (
                  <p className="text-sm text-gray-500">Fetching options...</p>
                )}
              </div>
            );
          }

          if (f.fieldType === "tagSelect") {
            // Use jsonKeyOptions for tag select when available
            const keyOptions = hasLength(jsonKeyOptions)
              ? jsonKeyOptions
              : f.options;

            const optionList: OptionType[] = keyOptions.map((value) => ({
              value,
              label: value,
            }));

            const handleSelectChange = (keyOptions: MultiValue<OptionType>) => {
              const values = keyOptions.map((option) => option.value);
              onSelectedJsonKeysChange?.(values);
            };

            if (!hasLength(keyOptions)) return null;

            return (
              <div className="flex flex-col gap-2" key={f.id}>
                <label
                  htmlFor={f.id}
                  className="block pl-1 font-medium text-gray-900"
                >
                  {f.label}
                </label>
                <div className="flex flex-wrap gap-2">
                  <div>
                    <Select
                      // defaultValue={optionList.slice(0, 9)}
                      isMulti
                      name="colors"
                      options={optionList}
                      className={twMerge(
                        `w-[602.41px]`,
                        `${errors.includes(f.id) ? "border border-red-600" : ""} `,
                      )}
                      classNamePrefix="select"
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          width: "602.41px",
                          fontFamily: "",
                        }),
                      }}
                      onChange={handleSelectChange}
                    />
                  </div>
                </div>
              </div>
            );
          }
          return null;
        }

        if (f.fieldType === "textarea") {
          const id = `${selectedTool.id}-${f.label}`;
          return (
            <div className={`flex flex-col gap-2`} key={f.id}>
              <label className="pl-1" htmlFor={id}>
                {f.label}
              </label>
              <ColumnsTextArea
                id={id}
                placeholder={f.placeholder}
                overrideClasses={`${errors.includes(f.id) ? "border border-red-600 hover:border-red-800" : ""} `}
                val={columnForm[f.id] || ""}
                setVal={(v) => {
                  if (errors.includes(f.id)) {
                    setErrors((errs) => [...errors.filter((e) => e !== f.id)]);
                  }
                  setColumnForm((columForm) => ({
                    ...columForm,
                    [f.id]: v,
                  }));
                }}
                columns={columns}
              />
            </div>
          );
        } else if (f.fieldType === "keySelect") {
          const id = `${selectedTool.id}-${f.label}`;
          const options: Column[] | string[] =
            f.options === "__COLUMN__" ? columns : f.options;

          return (
            <div className="flex flex-col gap-2" key={f.id}>
              <label
                htmlFor={id}
                className="block pl-1 font-medium text-gray-900 "
              >
                {f.label}
              </label>
              <select
                defaultValue={columnForm[f.id] || undefined}
                value={columnForm[f.id] || ""}
                onChange={(e) => {
                  setErrors((errs) => [...errors.filter((e) => e !== f.id)]);
                  setColumnForm((columForm) => ({
                    ...columForm,
                    [f.id]: e.target.value,
                  }));
                }}
                id="countries"
                className={twMerge(
                  `block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 `,
                  `${errors.includes(f.id) ? "border border-red-600" : ""} `,
                )}
              >
                <option value={""}>Not selected</option>
                {options.map((o) => {
                  const currOption =
                    typeof o === "string"
                      ? { id: o, display: o }
                      : {
                          id: o.data_field,
                          display: getColumnDisplayName(o),
                        };

                  const matchedValues = getValuesForField(
                    companies || [],
                    currOption.id,
                    10,
                  );

                  return (
                    <option
                      value={currOption.id}
                      key={currOption.id}
                      title={matchedValues}
                    >
                      {sentenceCaseShort(currOption.display, "_", 80)}
                    </option>
                  );
                })}
              </select>
            </div>
          );
        } else if (f.fieldType === "tagSelect") {
          const id = `${selectedTool.id}-${f.label}`;

          const optionList: OptionType[] = f.options.map((value) => ({
            value,
            label: value,
          }));

          const handleSelectChange = (options: MultiValue<OptionType>) => {
            const values = options.map((option) => option.value);
            setSelectedValues(values);
          };

          const handleInputChange = (value: string, inputValue: string) => {
            setValueInputs((prev) => {
              const updated = { ...prev, [value]: inputValue };

              // Update the form field with the combined result
              const result = selectedValues
                .map((v) => `${v}:${updated[v] || ""}`)
                .join(", ");
              setTestTableValues(result);

              setErrors((errs) => errs.filter((e) => e !== f.id));
              setColumnForm((columForm) => ({
                ...columForm,
                [f.id]: result,
              }));

              return updated;
            });
          };

          return (
            <div className="flex flex-col gap-2" key={f.id}>
              <label
                htmlFor={id}
                className="block pl-1 font-medium text-gray-900 "
              >
                {f.label}
              </label>
              <div>
                <CreatableSelect
                  // defaultValue={optionList.slice(0, 9)}
                  isMulti
                  name="colors"
                  options={optionList}
                  className={twMerge(
                    `w-[602.41px]`,
                    `${errors.includes(f.id) ? "border border-red-600" : ""} `,
                  )}
                  classNamePrefix="select"
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: "602.41px",
                      fontFamily: "",
                    }),
                  }}
                  onChange={handleSelectChange}
                />
              </div>
              {selectedValues.length > 0 && (
                <div className="mt-4">
                  <table className="w-full table-auto border-collapse border border-gray-300">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="border border-gray-300 px-4 py-2">
                          Category
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Description
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedValues.map((value, index) => (
                        <tr key={index} className="text-left">
                          <td className="border border-gray-300 px-4 py-2">
                            {value}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            <input
                              type="text"
                              placeholder={`Add info for ${value}`}
                              className="w-full rounded border border-gray-300 px-2 py-1"
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                handleInputChange(value, inputValue);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="mt-3">
                    <label className="" htmlFor={id}>
                      Test Input
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setTestEmailContent(e.target.value)}
                      className="w-full rounded border border-gray-300 px-2 py-1"
                    />
                    <button
                      onClick={() =>
                        handleTestCategorizeLead(
                          testTableValues,
                          testEmailContent,
                        )
                      }
                      className="mt-2 gap-1.5 rounded-md border-purple bg-primary px-2 py-1 text-white"
                    >
                      Test
                    </button>
                    {testResultOutput && (
                      <>
                        <div className="mt-3">
                          <p>Result: {testResultOutput}</p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        }
        return <>x</>;
      })}
    </>
  );
};
export function ColumnConditionAdder({
  conditionalColumn,
  setConditionalColumn,
  columns,
  filterForm,
  setFilterForm,
}: {
  columns: Column[];
  conditionalColumn: boolean;
  setConditionalColumn: React.Dispatch<React.SetStateAction<boolean>>;
  filterForm: FilterFormField[];
  setFilterForm: React.Dispatch<React.SetStateAction<FilterFormField[]>>;
}) {
  // rand id to prevent problems when multiple condition view are in same page
  // TODO: find a better way to handle this
  const [randId] = useState(Math.random().toString());

  return (
    <div>
      <div className={`flex gap-2`} key={randId}>
        <input
          checked={conditionalColumn}
          onChange={(e) => {
            setConditionalColumn(e.target.checked);
          }}
          id={randId}
          type="checkbox"
          className=""
        />
        <label
          className={twMerge(
            "pl-1 text-gray-500 transition-all duration-300 hover:text-gray-800",
            `${conditionalColumn ? "text-gray-800" : ""}`,
          )}
          htmlFor={randId}
        >
          Run conditionally, only where specific conditions are true, e.g.
          "employees"=="10"
        </label>
      </div>
      {conditionalColumn && columns && (
        <ColumnConditions
          columns={columns}
          filterForm={filterForm}
          setFilterForm={setFilterForm}
          runFilter={false}
        />
      )}
    </div>
  );
}

export function ColumnConditions({
  columns,
  filterForm,
  setFilterForm,
  runFilter,
}: {
  columns: Column[];
  filterForm: FilterFormField[];
  setFilterForm: React.Dispatch<React.SetStateAction<FilterFormField[]>>;
  runFilter: boolean;
}) {
  const [, setSearchParams] = useSearchParams();
  const { remainingRows } = useStateContext();

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      console.log("Remaining rows updated:......", remainingRows);
    }
  }, [remainingRows]);

  const filterOptions: Array<{ id: FilterFormField["type"]; name: string }> = [
    { id: "equals", name: "Equals" },
    { id: "notEquals", name: "Not Equals" },
    { id: "startsWith", name: "Starts With" },
    { id: "contains", name: "Contains" },
    { id: "exists", name: "Exists" },
    { id: "greaterThan", name: "Greater Than" },
    { id: "lessThan", name: "Less Than" },
  ];

  function handleFormUpdate(
    f: FilterFormField,
    k: keyof FilterFormField,
    v: string | number | boolean,
  ) {
    setFilterForm((prev) =>
      getUpdatedForm({
        prev,
        updatedField: f,
        k,
        v: k === "value" && f.type === "exists" ? v === "true" : v,
      }),
    );
  }
  function handleFilterRemove(f: FilterFormField) {
    setFilterForm((prev) => {
      const updatedFilters = [...prev.filter((ff) => ff.id !== f.id)];
      submit(updatedFilters);
      return updatedFilters;
    });
  }
  async function submit(filterForm: FilterFormField[]) {
    const filterToSend: { [key: string]: any }[] = [];
    filterForm.forEach((f) => {
      if (f.type === "greaterThan" || f.type === "lessThan") {
        f.value = parseInt(f.value as string);
      }
      if (f.type === "exists") {
        f.value = f.value === true || f.value === "true";
      }
      filterToSend.push(f);
    });
    if (false) {
      // console.log(searchParams);
    }
    const encoded = encodeURIComponent(JSON.stringify(filterToSend));
    setSearchParams((prev) => {
      prev.set("filter", encoded);
      return prev;
    });
  }

  return (
    <div>
      <FilterFields
        cols={columns.map((c) => ({
          name: getColumnDisplayName(c),
          id: c.data_field,
        }))}
        filterForm={filterForm}
        handleFormUpdate={handleFormUpdate}
        handleFilterRemove={handleFilterRemove}
        filterOptions={filterOptions}
      />
      <div className="flex items-center gap-4">
        <IconButton
          overrideClasses="max-w-max"
          icon={<PlusIcon className="fill-purpleHover" />}
          text="Add filter"
          variant="outline"
          onClick={() => {
            setFilterForm((prev) => [
              ...prev,
              {
                id: Math.random().toString(),
                name: "",
                key: "",
                type: "",
                value: "",
              },
            ]);
          }}
        />
        {filterForm.length > 0 && remainingRows !== null && (
          <span className="text-purple">{remainingRows} rows remaining</span>
        )}
      </div>

      {filterForm.length >= 1 && runFilter === true && (
        <div className="mt-12 flex justify-center">
          <IconButton
            overrideClasses="max-w-max"
            icon={<FunnelIcon width={20} className="fill-white" />}
            text={"Run filter"}
            variant="fill"
            onClick={() => {
              submit(filterForm);
            }}
          />
        </div>
      )}
    </div>
  );
}
