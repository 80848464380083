import {
  FunnelIcon,
  InformationCircleIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import {
  KurationToolColumn,
  CustomToolColumn,
  Column,
} from "src/hooks/useChatTable";
import CustomDropdown from "../elements/CustomDropDownMenu";
import { IconButton } from "../elements/IconButton";
import CopyIcon from "../svgs/CopyIcon";
import StarsIcon from "../svgs/Stars";
import { getColumnDisplayName, getToolDisplay, sentenceCase } from "../utils";
import { runMenu } from "./dropdownMenus";
import { Company, FreeDict } from "src/models/data";
import { Field } from "src/models/tools";
import { twMerge } from "tailwind-merge";
import { SimpleSpinner } from "../elements/Spinner";

export function getOriginalOrAltItem(
  row: Company,
  alternativeColumns: AltColumn[],
  column: string,
): Field | undefined {
  return (
    row
      .filter(
        (item) =>
          // we need to get the value for column
          item.data_field.toLowerCase() === column.toLowerCase() ||
          // or if we are using an alternative column, we need to get original columns data since the "column" would be the alt column
          // e.g. original = name, alt = company_name
          // in this scenario -> column would be company_name (never name because we filtered it out)
          // but when we display its information
          // we should get the name's attribute IFF the alt column was not found, i.e. data_field === column failed.
          item.data_field ===
            alternativeColumns
              .find((a) => a.alternative.toLowerCase() === column.toLowerCase())
              ?.original.toLowerCase(),
      )
      //get the second last item (because it should be the latest one)
      .at(-1)
  );
}

export interface AltColumn {
  original: string;
  alternative: string;
}
export function getColumnIdsFromColumnList(column_list: Column[] | undefined) {
  return Array.from(new Set(column_list?.map((c) => c.id)));
}

function textDict(dict: FreeDict) {
  return Object.entries(dict)
    .map((e) => `${sentenceCase(e[0], "_")}: ${e[1]}`)
    .join(", \n");
}
export type ColRunner = ({col}: {
  col: KurationToolColumn | CustomToolColumn;
  count: number;
  pause: boolean;
  error_only: boolean;
  where: string[];
  start_from_index: number;
  end_at_index: number;
  rerun_loading_rows: boolean;
}) => Promise<null>;

export function ToolColumnHeader({
  col,
  colRunner,
  isRunLoading,
  columnList,
}: {
  col: KurationToolColumn | CustomToolColumn;
  isRunLoading: boolean;
  columnList?: Array<Column>;
  colRunner: ColRunner;
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [copyMessage, setCopyMessage] = useState<string | null>(null);

  const title = getToolDisplay(col);

  const conditions = replaceIdsWithQueries(
    textDict(
      col.type === "tool_column" ? col.column_data : col.custom_tool.fields,
    ),
    columnList ?? [],
  );

  const handleCopy = () => {
    navigator.clipboard.writeText(conditions).then(() => {
      setCopyMessage("Copied!");
      setTimeout(() => setCopyMessage(null), 1000); // Dismiss after 1 second
    });
  };

  function replaceIdsWithQueries(text: string, array: Column[]): string {
    return array.reduce((resultText, item) => {
      const regex = new RegExp(`/${item.id}`, "g");
      return resultText.replace(regex, getColumnDisplayName(item) ?? "");
    }, text);
  }

  // console.log("i am colsss", columnList);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="relative z-50 flex h-full min-w-min cursor-pointer items-center gap-1  px-5 py-3 text-lg  font-normal transition-all duration-300"
    >
      <div className="flex  max-w-[400px] items-center  gap-1 truncate">
        <CustomDropdown
          openInModal
          blurBg={false}
          options={runMenu({
            col,
            colRunner,
          })}
          opener={
            isRunLoading ? (
              <SimpleSpinner radius={20} overrideClasses="border-black" />
            ) : (
              <IconButton
                overrideClasses={twMerge(
                  "text-small h-full w-min gap-0 px-1 leading-normal text-purpleHover",
                  `${col.run_status === "running" ? "gap-1 border-green-500 text-green-500" : ""}`,
                )}
                onClick={() => {}}
                variant="outline"
                icon={
                  col.run_status === "running" ? (
                    <SimpleSpinner
                      radius={15}
                      overrideClasses="border-green-500"
                    />
                  ) : (
                    <PlayIcon
                      title="run"
                      width={20}
                      className="fill-purpleHover"
                    />
                  )
                }
                text={`${col.run_status === "running" ? `Running ${col.run_count} rows` : "Run"}`}
              />
            )
          }
        />
        <ColNameWithIcon col={col} />
      </div>
      {isHovered && (
        <div className="z-100 absolute left-20 top-12 gap-2 rounded-lg border border-gray-300 bg-white p-4 shadow-lg">
          <div className="relative flex items-center gap-2">
            <pre className="text-xs">Column Name: {title}</pre>
          </div>
          <div className="relative mt-1 flex items-center gap-2">
            <pre className="whitespace-normal text-xs">{conditions}</pre>
            <button
              onClick={handleCopy}
              onMouseEnter={() => setCopyMessage("Copy")}
              onMouseLeave={() =>
                !copyMessage?.includes("Copied") && setCopyMessage(null)
              }
              className="relative mt-2 rounded bg-gray-200 p-1 text-center transition hover:bg-gray-300"
            >
              <CopyIcon />
              {/* Tooltip */}
              {copyMessage && (
                <div className="absolute bottom-full left-1/2 mb-1 -translate-x-1/2 transform rounded bg-gray-800 px-2 py-1 text-xs text-white shadow-lg">
                  {copyMessage}
                </div>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
export function ToolIcon({
  col,
}: {
  col: KurationToolColumn | CustomToolColumn;
}) {
  return col.type === "tool_column" ? (
    <img
      width={24}
      className="object-fill"
      src={col.tool.icon}
      alt={col.tool.name}
    />
  ) : (
    <div className="h-6 w-6">
      <StarsIcon fill="#e08588" />
    </div>
  );
}
export function ColNameWithIcon({
  col,
}: {
  col: KurationToolColumn | CustomToolColumn;
}) {
  // const [width, setWidth] = useState("200px");

  // const handleMouseDown = (event: React.MouseEvent) => {
  //   const startX = event.clientX;

  //   const handleMouseMove = (moveEvent: MouseEvent) => {
  //     const newWidth = moveEvent.clientX - startX + parseInt(width, 10);
  //     setWidth(newWidth > 50 ? `${newWidth}px` : "50px");
  //   };

  //   const handleMouseUp = () => {
  //     document.removeEventListener("mousemove", handleMouseMove);
  //     document.removeEventListener("mouseup", handleMouseUp);
  //   };

  //   document.addEventListener("mousemove", handleMouseMove);
  //   document.addEventListener("mouseup", handleMouseUp);
  // };

  return (
    <>
      <ToolIcon col={col} />
      <div className="relative flex items-center justify-between">
        <p>{getColumnDisplayName(col)}</p>
      </div>
    </>
  );
}
export function SourceTooltip({ content }: { content: string }) {
  return (
    <div className="absolute left-1/2 z-10 mx-auto hidden max-w-[20ch] -translate-x-1/2  truncate rounded-md bg-dark p-1 text-sm text-white opacity-0 shadow-lg transition-opacity group-hover:block group-hover:opacity-100 md:max-w-[24cqw]">
      <div>Click cell to explore details.</div>
      {!!content && (
        <div>
          Source: <UrlOrText content={content} />
        </div>
      )}
    </div>
  );
}
function UrlOrText({ content }: { content: string }) {
  // Check if the content resembles a URL without protocol
  const isUrlWithoutProtocol = /^[^\s]+\.[^\s]+$/.test(content);

  if (isUrlWithoutProtocol) {
    let fullUrl = content;
    if (typeof fullUrl === "string" && !fullUrl.startsWith("http")) {
      fullUrl = `http://${content}`;
    }
    return (
      <span
        title={content}
        className="relative inline  max-w-[20ch] truncate whitespace-nowrap px-3  py-4 font-medium md:max-w-[24cqw]"
      >
        <a href={fullUrl} target="_blank" rel="noopener noreferrer">
          {content}
        </a>
      </span>
    );
  }
  return (
    <span
      title={content}
      className="inline whitespace-nowrap  px-3 py-4 font-medium"
    >
      {typeof content === "string" ? content : JSON.stringify(content)}
    </span>
  );
}
export const CellText = React.forwardRef<
  HTMLTableCellElement,
  { content: string; domainName: string | null }
>(({ content, domainName }, ref) => {
  if (!content || content === "unknown" || content === "null") {
    return <span className=" px-3 py-4"></span>;
  }
  if (content === "") {
    return <span className=" px-3 py-4"></span>;
  }
  if (domainName) {
    return <CompanyNameWithLogo content={content} domainName={domainName} />;
  } else {
    return <UrlOrText content={content} />;
  }
});
export function LoadingCell({
  text,
}: {
  text: "Running" | "Saving" | "Waiting for dependency";
}) {
  return (
    <div className="ml-3">
      <div className="flex px-2">
        <div className="flex items-center justify-center rounded-md border-2 border-purple bg-lightpurple p-1 text-white">
          <SimpleSpinner radius={20} overrideClasses="border-purple" />
          <span className="text-md px-2 font-medium text-purple">{text}</span>
        </div>
      </div>
    </div>
  );
}
export function SkippedCell() {
  return (
    <div>
      <div className="max-w-[20ch] truncate  md:max-w-[24cqw] ">
        <div className="flex items-center px-4 py-4 ">
          <div className="relative text-gray-500">
            <FunnelIcon width={25} />
            <InformationCircleIcon
              width={12}
              className="absolute top-0  rounded-full bg-white"
            />
          </div>
        </div>
      </div>
      <SourceTooltip content={"skipped"} />
    </div>
  );
}
export function CompanyNameWithLogo({
  content,
  domainName,
}: {
  content: string;
  domainName: string;
}) {
  const errorSrc = "/error-image.svg";

  return (
    <div className="relative flex items-center justify-start gap-1 px-3 py-3">
      <img
        className="h-6 w-6 rounded-md object-cover"
        src={`https://logo.clearbit.com/${domainName}`}
        alt={`${content} company logo`}
        height={24}
        width={24}
        loading="lazy"
        onError={(e) => {
          if (e.currentTarget.src !== errorSrc) {
            e.currentTarget.src = errorSrc;
          }
        }}
      />
      <span className="relative inline max-w-[64cqw] truncate text-sm font-medium">
        {sentenceCase(content)}
      </span>
    </div>
  );
}
