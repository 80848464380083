import * as React from "react";
const KurationTable = ({ stroke = "#5733CF", ...props }) => (
  <svg
    viewBox="0 0 24 25"
    stroke={stroke}
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 4V21M20.5 10H3.5M3 12.5C3 8.257 3 6.136 4.318 4.818C5.636 3.5 7.758 3.5 12 3.5C16.243 3.5 18.364 3.5 19.682 4.818C21 6.136 21 8.258 21 12.5C21 16.743 21 18.864 19.682 20.182C18.364 21.5 16.242 21.5 12 21.5C7.757 21.5 5.636 21.5 4.318 20.182C3 18.864 3 16.742 3 12.5Z"
      stroke={stroke}
      strokeWidth="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default KurationTable;
