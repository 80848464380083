import { useState, useRef } from "react";
import { toast } from "react-toastify";
import Papa from "papaparse";
import ChatContainer from "src/pages/chat/ChatContainer";
import { normalizeCompanies } from "src/components/utils";
import KurationHand from "src/components/svgs/KurationHand";
import KurationGridView from "src/components/svgs/KurationGridView";
import KurationCheckMark from "src/components/svgs/KurationCheckMark";
import KurationUpload from "src/components/svgs/KurationUpload";
import type { Company } from "src/models/data";
import { CreateAndSendMessage } from "src/models/data";
import { CompanyListTable } from "src/components/table/CompanyListTable";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { KurationInitialLists } from "./KurationInitialLists";
import KurationCheckTools from "./KurationCheckTools";

const HomeTabs = () => {
  const [activeTab, setActiveTab] = useState("csv");
  const chatContainerRef = useRef<{
    createAndSendMessage: CreateAndSendMessage;
  }>(null);
  const [userUploadedJson, setUserUploadedJson] = useState<
    Record<string, string>[] | null
  >(null);
  const [normalizedResults, setNormalizedResults] = useState<{
    companies: Company[];
  } | null>(null);
  const [dragActive, setDragActive] = useState(false);

  function handleFileChange(file: File) {
    const toastId = toast.loading("File uploading");

    const rawCompanies: Array<Record<string, string>> = [];

    Papa.parse(file, {
      worker: true,
      header: true,
      step: (row) => {
        rawCompanies.push(row.data as Record<string, string>);
      },
      complete: () => {
        toast.update(toastId, {
          render: "File uploaded successfully",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });

        setUserUploadedJson(rawCompanies);
        setNormalizedResults({
          companies: normalizeCompanies(rawCompanies.slice(0, 100)),
        });
      },
      error: (error) => {
        toast.update(toastId, {
          render: "Error processing file: " + error.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      },
    });
  }

  function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileChange(e.dataTransfer.files[0]);
    }
  }

  function handleDrag(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }

  const handleButtonClick = () => {
    document.getElementById("uploadcsv")?.click();
  };

  const handleSendMessage = async (msg: string) => {
    if (chatContainerRef.current) {
      const toastId = toast.loading("Creating chat...");

      try {
        await toast.promise(
          chatContainerRef.current.createAndSendMessage(msg, userUploadedJson),
          {
            pending: "Creating chat...",
            success: "Chat created successfully! 🎉",
            error: "Failed to create chat. Please try again.",
          },
        );
      } catch (e) {
        console.error(e);
      } finally {
        toast.dismiss(toastId);
      }
    }
  };

  return (
    <div className="pb-10">
      <div className="inset-0 z-50 mb-6 flex rounded-full !bg-[#EAFAEE]">
        <div className="hidden">
          <ChatContainer ref={chatContainerRef} />
        </div>
        <button
          className={`flex flex-1 items-center justify-center gap-2 rounded-full px-4 py-3 text-[#4F4F4F] hover:bg-[#ADEBBB] ${activeTab === "csv" ? "bg-[#ADEBBB] !text-black" : ""}`}
          onClick={() => setActiveTab("csv")}
        >
          <KurationUpload
            className="h-6 w-6"
            fill={activeTab === "csv" ? "black" : "#4F4F4F"}
          />
          Drop a CSV file
        </button>

        <button
          className={`flex flex-1 items-center justify-center gap-2 rounded-full px-4 py-3 text-[#4F4F4F] hover:bg-[#ADEBBB] ${activeTab === "scratch" ? "bg-[#ADEBBB] !text-black" : ""}`}
          onClick={() => setActiveTab("scratch")}
        >
          <KurationHand
            className="h-6 w-6"
            fill={activeTab === "scratch" ? "black" : "#4F4F4F"}
          />
          Start From Scratch
        </button>
        <button
          className={`flex flex-1 items-center justify-center gap-2 rounded-full px-4 py-3 text-[#4F4F4F] hover:bg-[#ADEBBB] ${activeTab === "tools" ? "bg-[#ADEBBB] !text-black" : ""}`}
          onClick={() => setActiveTab("tools")}
        >
          <KurationGridView
            className="h-6 w-6"
            stroke={activeTab === "tools" ? "black" : "#4F4F4F"}
          />
          Check Our Tools
        </button>
      </div>
      <div>
        {activeTab === "csv" && (
          <div>
            <div
              className={`flex w-full flex-col items-center justify-center rounded-lg border-2 border-dashed border-[#DFE1FF] bg-[#DFE1FF] bg-opacity-[15%] p-12 transition-colors ${
                dragActive ? "border-purple bg-purple/10" : ""
              }`}
              onDragEnter={handleDrag}
              onDragOver={handleDrag}
              onDragLeave={handleDrag}
              onDrop={handleDrop}
            >
              <div className="flex h-40 w-full flex-col items-center justify-center">
                <div className="mb-4 flex h-20 w-20 items-center justify-center rounded-md bg-gray-100">
                  <img
                    className="w-44"
                    src="/csv-format.png"
                    alt="CSV Format"
                  />
                </div>
                <h3 className="mb-1 text-lg font-medium">Drag and drop</h3>
                <p className="text-lightgray mb-4 text-sm">
                  CSV file (MAX. 10000 rows)
                </p>
              </div>
              <input
                type="file"
                name="uploadcsv"
                id="uploadcsv"
                accept=".csv"
                className="hidden"
                onChange={(e) => {
                  if (e.target.files?.[0]) {
                    handleFileChange(e.target.files[0]);
                  }
                }}
              />
              <button
                className="text-md mt-2 flex items-center gap-1 rounded-md border border-primary px-4 py-2 text-primary"
                onClick={handleButtonClick}
              >
                <span className="text-lg">+</span> Choose and upload a CSV file
              </button>

              {normalizedResults && (
                <div className="flex h-full w-[1100px] flex-col justify-between">
                  <div className="h-max max-h-[90vh] flex-1 flex-grow overflow-y-auto">
                    <div className="mb-2 flex items-start justify-start gap-2">
                      <CheckCircleIcon className="h-5 w-5 text-green-500" />
                      <p className="text-sm text-gray-700">
                        List attached. (Showing first 100)
                      </p>
                    </div>
                    <div className="flex-grow overflow-hidden rounded-lg border border-gray-200">
                      <CompanyListTable
                        companies={normalizedResults.companies}
                        tableType="noTable"
                        startIndex={1}
                        height="small"
                      />
                    </div>
                  </div>
                </div>
              )}

              {userUploadedJson !== null && (
                <>
                  <hr className="w-full" />
                  <div className="mt-4 flex justify-end">
                    <button
                      onClick={() =>
                        handleSendMessage(
                          "Here's the CSV. Let me know what's next.",
                        )
                      }
                      className="flex items-center gap-2 rounded bg-purple p-[7px] text-white outline-none hover:bg-purpleHover focus:bg-purpleHover"
                    >
                      <p>Submit</p>
                    </button>
                  </div>
                </>
              )}
            </div>

            <div className="mt-8 w-full">
              <h4 className="mb-3 text-sm font-medium">
                Tips for successful upload:
              </h4>
              <ul className="m-0 space-y-2">
                <li className="flex items-start gap-2">
                  <KurationCheckMark className="mt-0.5 h-4 w-4 flex-shrink-0 text-green-500" />
                  <span className="text-sm">
                    Ensure your CSV file is properly formatted with headers
                  </span>
                </li>
                <li className="flex items-start gap-2">
                  <KurationCheckMark className="mt-0.5 h-4 w-4 flex-shrink-0 text-green-500" />
                  <span className="text-sm">
                    Remove any special characters from column names
                  </span>
                </li>
                <li className="flex items-start gap-2">
                  <KurationCheckMark className="mt-0.5 h-4 w-4 flex-shrink-0 text-green-500" />
                  <span className="text-sm">
                    Make sure company names and websites are accurate
                  </span>
                </li>
                <li className="flex items-start gap-2">
                  <KurationCheckMark className="mt-0.5 h-4 w-4 flex-shrink-0 text-green-500" />
                  <span className="text-sm">
                    Verify all URLs are complete and include http:// or https://
                  </span>
                </li>
              </ul>
            </div>
          </div>
        )}
        {activeTab === "scratch" && (
          <div>
            <KurationInitialLists />
          </div>
        )}
        {activeTab === "tools" && (
          <div>
            <KurationCheckTools />
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeTabs;
