import { useState } from "react";
import { useNavigate } from "react-router-dom";
import backend_services from "src/services/backend_service";
import Spinner from "../elements/Spinner";
import { toast } from "react-toastify";
import { useAuth } from "src/context/AuthContext";
import { TableState } from "src/hooks/useChatTable";
import CloseIcon from "../svgs/CloseIcon";
import IndeedJobScraperForm from "./forms/IndeedJobScraperForm";
import { useStateContext } from "src/context/StateContext";
import SearchIcon from "../svgs/Search";
import { OfficeBagIcon } from "../svgs/OfficeBagIcon";

interface IndeedJobScraperProps {
  handleClose: () => void;
  initialSearchUrl: string;
  table_state?: TableState;
  chat_id?: string;
}

const IndeedJobScraper: React.FC<IndeedJobScraperProps> = ({
  handleClose,
  initialSearchUrl = "",
  table_state,
  chat_id,
}) => {
  const { setSidebarOpen } = useStateContext();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [searchUrl, setSearchUrl] = useState(initialSearchUrl);
  const navigator = useNavigate();

  async function handleSearch() {
    try {
      setLoading(true);

      if (!user) {
        toast.error("User is not authenticated.");
        return;
      }

      if (!searchUrl || searchUrl.trim() === "") {
        toast.error("Please enter a valid job title.");
        return;
      }

      const indeedUrlPattern =
        /^https?:\/\/([a-z0-9-]+\.)*indeed\.(com|[a-z]{2,})(\/jobs\?.+)$/i;
      if (!indeedUrlPattern.test(searchUrl)) {
        toast.error("Please enter a valid Indeed job search URL.");
        setLoading(false);
        return;
      }

      if (!indeedUrlPattern) {
        toast.error("Please enter a valid Indeed URL");
      }

      const requestBody: any = {
        search_url: searchUrl,
      };

      let response;
      if (table_state === TableState.ERROR) {
        // Retry endpoint
        response = await backend_services.fetchProtectedData(
          `/initial_lists/retry/indeedJob/${chat_id}`,
          user.getIdToken(),
          undefined,
          "POST",
          requestBody,
        );

        if (response.ok) {
          const result = await response.json();
          toast.success(result.message || "Retry initiated successfully.");
          handleClose(); // Close modal after retry
        } else {
          throw new Error(await response.text());
        }
      } else {
        // Default endpoint
        response = await backend_services.fetchProtectedData(
          `/initial_lists/indeedJob`,
          user.getIdToken(),
          undefined,
          "POST",
          requestBody,
        );

        if (response.ok) {
          const result = await response.json();
          if (result?.chat_id) {
            navigator(`/chat/${result.chat_id}`); // Redirect to chat only for default request
            toast.success(result.message || "Request submitted successfully.");
            handleClose();
            setSidebarOpen(false);
          } else {
            toast.error(result.detail || "An error occurred.");
          }
        } else {
          throw new Error(await response.text());
        }
      }
    } catch (error: any) {
      console.error("Error:", error.message || error);
      console.log("erryyaaaa", error.message);
      toast.error(error.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div
        className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform ${loading ? "opacity-95" : null} z-50 h-[650px] w-[700px] overflow-y-auto rounded-lg bg-white px-6 py-6 shadow-lg`}
      >
        {loading && <Spinner text="Searching..." onWhiteBackground />}
        <div className="flex flex-col gap-5">
          <div>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <img
                  src="https://cdn.prod.website-files.com/64acc1670486f7df09be2423/6790a92b6b005595027ad1a6_Indeed_2021_Icon_RGB_Blue.png"
                  alt="Indeed Job Scraper"
                  className="h-8 w-8 object-contain"
                />
                <div className="">
                  <h2 className="text-xl font-bold">Indeed Job Scraper</h2>
                  <p className="text-sm">One-click jobs import from Indeed!</p>
                </div>
              </div>

              <button
                className="col-end-7 justify-self-end text-gray-500 hover:text-gray-700"
                onClick={handleClose}
              >
                <CloseIcon />
              </button>
            </div>

            <div className="flex items-center justify-center gap-4 pt-6">
              <div className="flex flex-col items-center">
                <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                  <OfficeBagIcon color="black" className="h-6 w-6" />
                </div>
                <span className="text-sm">Enter Indeed Search URL</span>
              </div>
              <div className="h-[2px] w-8 bg-gray-300" />
              <div className="flex flex-col items-center">
                <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                  <SearchIcon className="h-6 w-6 text-gray-700" />
                </div>
                <span className="text-sm">Get Results</span>
              </div>
            </div>
          </div>

          <IndeedJobScraperForm
            searchUrl={searchUrl}
            setSearchUrl={setSearchUrl}
            onSubmit={handleSearch}
          />

          <hr className="w-full" />
          <div className="pt-6">
            <h2 className="mb-4 text-sm font-bold">Example Output:</h2>
            <div className="overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="border-b">
                    <th className="px-4 py-3 text-left font-medium">Company</th>
                    <th className="px-4 py-3 text-left font-medium">JobUrl</th>
                    <th className="px-4 py-3 text-left font-medium">
                      Job Title
                    </th>
                    <th className="px-4 py-3 text-left font-medium">
                      Remote Work Model
                    </th>
                    <th className="px-4 py-3 text-left font-medium">Summary</th>
                    <th className="px-4 py-3 text-left font-medium">
                      Location
                    </th>
                    <th className="px-4 py-3 text-left font-medium">Salary</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b">
                    <td className="px-4 py-3">TechCorp Inc.</td>
                    <td className="px-4 py-3">
                      <a
                        href="https://www.techcorp.com/job/123"
                        className="text-blue-500"
                      >
                        Job Link
                      </a>
                    </td>
                    <td className="px-4 py-3">Software Developer</td>
                    <td className="px-4 py-3">On-Site</td>
                    <td className="px-4 py-3">
                      Responsible for developing scalable web applications and
                      services.
                    </td>
                    <td className="px-4 py-3">San Francisco, CA</td>
                    <td className="px-4 py-3">$70,000 - $90,000</td>
                  </tr>
                  <tr className="border-b">
                    <td className="px-4 py-3">CloudScale</td>
                    <td className="px-4 py-3">
                      <a
                        href="https://www.cloudscale.com/job/456"
                        className="text-blue-500"
                      >
                        Job Link
                      </a>
                    </td>
                    <td className="px-4 py-3">Cloud Engineer</td>
                    <td className="px-4 py-3">Remote</td>
                    <td className="px-4 py-3">
                      Work on cloud infrastructure optimization and maintenance.
                    </td>
                    <td className="px-4 py-3">New York, NY</td>
                    <td className="px-4 py-3">$50,000 - $60,000</td>
                  </tr>
                  <tr className="border-b">
                    <td className="px-4 py-3">InnoTech</td>
                    <td className="px-4 py-3">
                      <a
                        href="https://www.innotech.com/job/789"
                        className="text-blue-500"
                      >
                        Job Link
                      </a>
                    </td>
                    <td className="px-4 py-3">Technology Project Manager</td>
                    <td className="px-4 py-3">Hybrid</td>
                    <td className="px-4 py-3">
                      Lead and manage cutting-edge technology innovation
                      projects.
                    </td>
                    <td className="px-4 py-3">Austin, TX</td>
                    <td className="px-4 py-3">$120,000 - $150,000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndeedJobScraper;
