import React, { ReactElement } from "react";
import { twMerge } from "tailwind-merge";
interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ReactElement;
  isAccountPage?: boolean;
  text: ReactElement | string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  variant: "outline" | "fill";
  overrideClasses?: string;
  hideIcon?: boolean;
  iconOnly?: boolean;
  tooltip?: string;
}
export function KurationIconButton({
  icon,
  text,
  onClick,
  variant,
  isAccountPage = false,
  overrideClasses = "",
  hideIcon = false,
  iconOnly = false,
  tooltip = "",
  ...props
}: IconButtonProps) {
  const variants = {
    fill: "flex h-12 w-14 px-6 active:scale-90 transition-all duration-300 items-center justify-center gap-1.5 rounded-md  bg-primary text-base leading-[19.2px] text-white hover:bg-purpleHover",
    outline:
      "flex p-[7px] h-7 w-7 active:scale-90 transition-all duration-300 items-center justify-center rounded-md text-base leading-[19.2px] text-purpleHover hover:bg-lightpurple",
  };
  return (
    <button
      onClick={onClick}
      className={twMerge(overrideClasses, variants[variant])}
      title={tooltip}
      {...props}
    >
      <div hidden={hideIcon}>{icon}</div>
      <div hidden={iconOnly} className={isAccountPage ? "w-20 text-start" : ""}>
        {text}
      </div>
    </button>
  );
}
