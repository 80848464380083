import { CustomToolColumn, KurationToolColumn } from "src/hooks/useChatTable";
import CustomDropdown from "../elements/CustomDropDownMenu";
import HashtagIcon from "../svgs/Hashtag";
import NextIcon from "../svgs/Next";
import { useState } from "react";
import CustomModal from "../elements/CustomModel";
import { ColRunner } from "./tableUtils";

export function runMenu({
  colRunner,
  col,
}: {
  col: KurationToolColumn | CustomToolColumn;
  colRunner: ColRunner;
}) {
  let runSpecificRows = "1"; // Default value

  if (col.run_status === "running") {
    return [
      <button
        onClick={() => {
          colRunner({
            col,
            count: 0,
            pause: true,
            error_only: false,
            where: [],
            start_from_index: 0,
            end_at_index: -1,
            rerun_loading_rows: false,
          });
        }}
        className="flex w-full gap-2 rounded px-2.5 py-2 hover:bg-grey-600"
      >
        <div className="flex-grow text-left">Pause</div>
      </button>,
    ];
  }

  return [
    <button
      onClick={() => {
        colRunner({
          col,
          count: 10,
          pause: false,
          error_only: false,
          where: [],
          start_from_index: 0,
          end_at_index: -1,
          rerun_loading_rows: false,
        });
      }}
      className="flex w-full gap-2 rounded px-2.5 py-2 hover:bg-grey-600"
    >
      <div className="flex-grow text-left">Run next 10 rows</div>
    </button>,
    <button
      onClick={() => {
        colRunner({
          col,
          count: -1,
          pause: false,
          error_only: false,
          where: [],
          start_from_index: 0,
          end_at_index: -1,
          rerun_loading_rows: false,
        });
      }}
      className="flex w-full gap-2 rounded px-2.5 py-2 hover:bg-grey-600"
    >
      <div className="flex-grow text-left">Run all rows</div>
    </button>,
    <div className="flex items-center gap-2 px-2.5 py-2 ">
      <input
        placeholder="for eg. 25"
        className="h-8 w-24 rounded border pl-2"
        onChange={(e) => {
          runSpecificRows = e.target.value; // Store value dynamically
        }}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      />

      <button
        onClick={() => {
          colRunner({
            col,
            count: parseInt(runSpecificRows) || 0,
            pause: false,
            error_only: false,
            where: [],
            start_from_index: 0,
            end_at_index: -1,
            rerun_loading_rows: false,
          });
        }}
        className="flex w-full gap-2 rounded px-2.5 py-2 hover:bg-grey-600"
      >
        <div className="flex-grow text-left">Run</div>
      </button>
    </div>,
    <button
      onClick={() => {
        let options = {
          col,
          count: -1,
          pause: false,
          error_only: true,
          where: [],
          start_from_index: 0,
          end_at_index: -1,
          rerun_loading_rows: false,
        };
        colRunner(options);
      }}
      className="flex w-full gap-2 rounded px-2.5 py-2 hover:bg-grey-600"
    >
      <div className="flex-grow text-left">
        Re-Run <span className="text-red-500">Error</span> rows only
      </div>
    </button>,
    <WhereRun col={col} colRunner={colRunner} />,
  ];
}
function WhereRun({
  col,
  colRunner,
}: {
  col: KurationToolColumn | CustomToolColumn;
  colRunner: ColRunner;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [conditions, setConditions] = useState<string[]>([""]);
  const [runMode, setRunMode] = useState<
    "runAll" | "rerunWhere" | "error" | "stuck"
  >("runAll");
  const [startIndex, setStartIndex] = useState<string>("0");
  const [endIndex, setEndIndex] = useState<string>("");
  const [errors, setErrors] = useState<{[key: string]: string}>({});

  const addCondition = () => {
    setConditions([...conditions, ""]);
  };

  const updateCondition = (index: number, value: string) => {
    const newConditions = [...conditions];
    newConditions[index] = value;
    setConditions(newConditions);
  };

  const removeCondition = (index: number) => {
    setConditions(conditions.filter((_, i) => i !== index));
  };

  const handleRun = () => {
    const validConditions = conditions.filter((c) => c.trim() !== "");
    const isError = runMode === "error";
    const isRerunRunningRows = runMode === "stuck";
    const isRerunWhere = runMode === "rerunWhere";
    const isRunAll = runMode === "runAll";
    const startIdx = parseInt(startIndex) || 0;
    const endIdx = parseInt(endIndex) || -1;
    
    const newErrors: {[key: string]: string} = {};
    
    if (endIdx !== -1 && endIdx <= startIdx) {
      newErrors.endIndex = "End index must be greater than start index";
    }
    
    if (isRerunWhere && validConditions.length === 0) {
      newErrors.conditions = "At least one condition is required for Re-Run where mode";
    }
    
    setErrors(newErrors);
    
    if (Object.keys(newErrors).length === 0 && 
        (isRunAll || (isRerunWhere && validConditions.length > 0) || isError || isRerunRunningRows)) {
      let options = {
        col,
        count: -1,
        pause: false,
        error_only: isError,
        where: validConditions,
        start_from_index: parseInt(startIndex) || 0,
        end_at_index: parseInt(endIndex) || -1,
        rerun_loading_rows: isRerunRunningRows,
      };
      colRunner(options);
      setIsModalOpen(false);
    }
  };

  const clearConditions = () => {
    setConditions([""]);
  };

  return (
    <>
      <button
        onClick={(e) => {
          setIsModalOpen(true);
          e.stopPropagation();
        }}
        className="flex w-full items-center gap-2 rounded px-2.5 py-2 hover:bg-grey-600"
      >
        <span className="text-left">Run Advanced</span>
      </button>
      {isModalOpen && (
        <CustomModal isOpen={true} onClose={() => setIsModalOpen(false)}>
          <div
            className="max-w-screen-md rounded-lg bg-white p-6 shadow-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="mb-4 text-lg font-semibold">Advanced Run Options</h3>
            <div className="mb-4 flex gap-2">
              <button
                onClick={() => setRunMode("runAll")}
                className={`rounded px-3 py-1 ${runMode === "runAll" ? "bg-blue-600 text-white" : "bg-gray-200 hover:bg-gray-300"}`}
              >
                Run All Rows
              </button>
              <button
                onClick={() => setRunMode("rerunWhere")}
                className={`rounded px-3 py-1 ${runMode === "rerunWhere" ? "bg-blue-600 text-white" : "bg-gray-200 hover:bg-gray-300"}`}
              >
                Re-Run where
              </button>
              <button
                onClick={() => {
                  setRunMode("error");
                  clearConditions();
                }}
                className={`rounded px-3 py-1 ${runMode === "error" ? "bg-blue-600 text-white" : "bg-gray-200 hover:bg-gray-300"}`}
              >
                Re-Run Error Rows
              </button>
              <button
                onClick={() => {
                  setRunMode("stuck");
                  clearConditions();
                }}
                className={`rounded px-3 py-1 ${runMode === "stuck" ? "bg-blue-600 text-white" : "bg-gray-200 hover:bg-gray-300"}`}
              >
                Re-Run running Rows
              </button>
            </div>
            {runMode === "rerunWhere" && (
              <>
                <h4 className="mb-2 font-medium">Run where value equals (Optional)</h4>
                <div className="space-y-3">
                  {conditions.map((condition, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <div className="flex-grow">
                        <input
                          placeholder="Enter value e.g. Not found"
                          className={`h-8 w-full rounded border pl-2 ${errors.conditions ? 'border-red-500' : ''}`}
                          value={condition}
                          onChange={(e) => updateCondition(index, e.target.value)}
                        />
                        {index === 0 && errors.conditions && (
                          <p className="mt-1 text-sm text-red-500">{errors.conditions}</p>
                        )}
                      </div>
                      {conditions.length > 1 && (
                        <button
                          onClick={() => removeCondition(index)}
                          className="text-red-500 hover:text-red-700"
                        >
                          ×
                        </button>
                      )}
                    </div>
                  ))}
                  <button
                    onClick={addCondition}
                    className="mt-2 flex items-center gap-1 rounded bg-gray-200 px-3 py-1 text-sm hover:bg-gray-300"
                  >
                    <span>+ Add OR condition</span>
                  </button>
                </div>
              </>
            )}
            <div className="mt-4 space-y-4">
              <div>
                <h4 className="mb-2 font-medium">Start from row index</h4>
                <input
                  type="number"
                  min="0"
                  placeholder="Enter start index"
                  className="h-8 w-full rounded border pl-2"
                  value={startIndex}
                  onChange={(e) => setStartIndex(e.target.value)}
                />
              </div>
              <div>
                <h4 className="mb-2 font-medium">
                  End at row index (optional)
                </h4>
                <input
                  type="number"
                  min={parseInt(startIndex) + 1 || 1}
                  placeholder="Enter end index"
                  className={`h-8 w-full rounded border pl-2 ${errors.endIndex ? 'border-red-500' : ''}`}
                  value={endIndex}
                  onChange={(e) => setEndIndex(e.target.value)}
                />
                {errors.endIndex && (
                  <p className="mt-1 text-sm text-red-500">{errors.endIndex}</p>
                )}
              </div>
            </div>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={() => setIsModalOpen(false)}
                className="rounded bg-gray-200 px-3 py-1 hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleRun}
                className="rounded bg-blue-600 px-3 py-1 text-white hover:bg-blue-700"
              >
                Run
              </button>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
}
export function rowsMenu() {
  return [
    <div>
      <CustomDropdown
        classNames="w-full"
        opener={
          <button className="flex w-full gap-2 rounded px-2.5 py-2 hover:bg-grey-600">
            <div>
              <HashtagIcon />
            </div>
            <div className="flex-grow text-left">Coming Soon</div>
            <div>
              <NextIcon />
            </div>
          </button>
        }
        overrideClasses=""
        options={rowLimitMenu()}
      />
    </div>,
  ];
}

export function rowLimitMenu() {
  return [
    <div>
      <CustomDropdown
        classNames="w-full"
        opener={
          <button className="flex w-full gap-2 rounded px-2.5 py-2 hover:bg-grey-600">
            <div>
              <HashtagIcon />
            </div>
            <div className="flex-grow text-left">Coming Soon</div>
            <div>
              <NextIcon />
            </div>
          </button>
        }
        overrideClasses=""
        options={colLimitMenu()}
      />
    </div>,
  ];
}

export function colLimitMenu() {
  return [
    <div>
      <CustomDropdown
        classNames="w-full"
        opener={
          <button className="flex w-full gap-2 rounded px-2.5 py-2 hover:bg-grey-600">
            <div>
              <HashtagIcon />
            </div>
            <div className="flex-grow text-left">Coming Soon</div>
            <div>
              <NextIcon />
            </div>
          </button>
        }
        overrideClasses=""
        options={[]}
      />
    </div>,
  ];
}
