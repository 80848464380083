import React from "react";
import Stripe from "stripe";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  onSnapshot,
  addDoc,
  getFirestore,
  collection,
  getDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import firebase_app from "../firebase/config";
import { loadStripe } from "@stripe/stripe-js";
import { Company, Field, FinalListItem } from "../models/data";
import backend_services from "../services/backend_service";
import { SearchCompany } from "../models/company";
import {
  Column,
  CustomToolColumn,
  KurationToolColumn,
} from "src/hooks/useChatTable";
import Papa from "papaparse";
import { KurationTool } from "src/models/tools";

export const numberFormatter = Intl.NumberFormat("en", { notation: "compact" });
export async function saveChatName(
  chatId: string,
  newName: string,
  tokenPromise: Promise<string>,
) {
  const result = await backend_services.fetchProtectedData(
    `/chat_name/${chatId}`,
    tokenPromise,
    undefined,
    "POST",
    { name: newName },
  );
  if (!result || result.status !== 200) {
    console.log("something went wrong", result);
  }
}

export async function saveisPinnedState(
  chatId: string,
  isPinned: boolean,
  tokenPromise: Promise<string>,
) {
  const result = await backend_services.fetchProtectedData(
    `/chat_pinned/${chatId}`,
    tokenPromise,
    undefined,
    "POST",
    { isPinned: isPinned },
  );
  if (!result || result.status !== 200) {
    console.log("something went wrong", result);
  }
}

export async function deleteRows(
  chatId: string,
  tableId: string,
  row_ids: string[],
  tokenPromise: Promise<string>,
) {
  const result = await backend_services.fetchProtectedData(
    `/delete_rows/${chatId}/${tableId}`,
    tokenPromise,
    undefined,
    "DELETE",
    { row_ids: row_ids },
  );
  if (!result || result.status !== 200) {
    console.log("something went wrong", result);
  }
}

export const scrollToElement = ({
  containerRef,
}: {
  containerRef: React.MutableRefObject<null | HTMLDivElement>;
}) => {
  if (containerRef?.current) {
    containerRef.current.scrollIntoView({ behavior: "smooth" });
  } else {
  }
};
export function normalizeCompanies(
  companies: Array<Record<string, string>> | SearchCompany[],
): Company[] {
  console.log("normalizing companies");
  return companies.map((company) => {
    const newCompany: Company = [];
    for (const key in company) {
      if (Object.prototype.hasOwnProperty.call(company, key)) {
        if (key !== "_id" && key !== "") {
          const value = company[key];
          const item: Field = {
            data_field: key,
            value: value,
            source: "",
            type: "user_added",
            custom_column_id: "",
          };
          newCompany.push(item);
        }
      }
    }
    return newCompany;
  });
}
interface UniqueColumnsOptions {
  ignoreCustomColumns?: boolean;
  ignoreUserAddedOrAiFields?: boolean;
}
export function getUniqueColumns(
  companies: Array<Company>,
  options?: UniqueColumnsOptions,
) {
  const columns = new Set<string>();
  companies.forEach((company) => {
    company.forEach((attribute) => {
      if (
        options?.ignoreCustomColumns &&
        attribute.type === "custom_column" &&
        attribute.custom_column_id
      ) {
        // ignore special columns
      } else if (
        options?.ignoreUserAddedOrAiFields &&
        (attribute.type === "ai_generated" || attribute.type === "user_added")
      ) {
        // ignore ai generated or user added columns
      } else {
        columns.add(attribute.data_field.toLowerCase());
      }
    });
  });
  return Array.from(columns);
}

export function reshapeCompanyData(companyData: FinalListItem[]) {
  const results: Company[] = [];
  companyData?.forEach((company) => {
    if (typeof company.final_research_results == "object") {
      results.push(company.final_research_results);
    }
  });
  return results;
}
function reorderCompanyList(companyList: Company[]) {
  const data = companyList.map((company) => {
    return company.reduce(
      (dict, field) => {
        dict[field.data_field.toLowerCase()] = field.value || "";
        return dict;
      },
      {} as { [key: string]: string },
    );
  });
  return data;
}
function setUpDownload({
  data,
  orderedColumns,
  chatId,
}: {
  data: Array<{ [key: string]: string }>;
  orderedColumns: string[];
  chatId: string;
}) {
  const csv = Papa.unparse(data, {
    columns: orderedColumns, // Only include these fields
    quotes: true, // Optionally wrap each field with quotes
    quoteChar: '"',
    escapeChar: '"', // Escape quotes properly
  });
  const downloadLink = document.createElement("a");
  const blob = new Blob(["\ufeff", csv]);
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = `KurationList_${chatId}.csv`; //Name the file here
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
function getUniqueColNames(colsList: Column[]) {
  let displayNames = colsList.map((col) =>
    getColumnDisplayName(col).toLowerCase(),
  );
  colsList.forEach((col, index) => {
    const isNameMoreThanOnce =
      displayNames.filter(
        (name) => name === getColumnDisplayName(col).toLowerCase(),
      ).length > 1;
    if (isNameMoreThanOnce) {
      let instance = 0;
      displayNames = displayNames.map((name) => {
        if (name === getColumnDisplayName(col).toLowerCase()) {
          instance = instance + 1;
          return getColumnDisplayName(col).toLowerCase() + "_" + instance;
        }
        return name;
      });
    }
  });
  return displayNames;
}
export default function log(message?: any, ...optionalParams: any[]): void {
  if (process.env.NODE_ENV === "development") {
    const error = new Error();
    const stack = error.stack?.split("\n");
    // Index 2 because index 0 is the error message and index 1 is this function itself
    const callerInfo = stack?.[2]?.trim();

    console.log(`${callerInfo}:`);
    console.log(message, optionalParams);
  }
}
export function downloadList({
  companyList,
  currentOpenChatId = "",
  columns,
}: {
  companyList: Company[];
  columns?: Column[];
  currentOpenChatId: string | undefined;
}) {
  try {
    if (columns) {
      // convert list of fields to dicts
      const data = reorderCompanyList(companyList);
      // order columns, tools columns at end

      const nonToolCols = columns.filter(
        (a) => a.type === "normal" || a.type === "research_plan_column",
      );
      const reorderedNonToolColumnNames = reorderColumns(
        nonToolCols.map((c) => c.data_field),
      );
      const reorderedNonToolColumns = reorderedNonToolColumnNames.map(
        (c) => nonToolCols.find((nc) => nc.data_field === c) || nonToolCols[0],
      );
      const toolCols = columns.filter(
        (b) => b.type === "custom_tool_column" || b.type === "tool_column",
      );
      const orderedColumns = [...reorderedNonToolColumns, ...toolCols];
      // convert to human names -> might have duplicates
      const uniqueOrderedKeys = getUniqueColNames(orderedColumns);

      // convert each column id to column display name for human readability
      const namedData: { [key: string]: string }[] = data.map((c) => {
        const newDict: { [key: string]: string } = {};
        orderedColumns.forEach((col, index) => {
          newDict[uniqueOrderedKeys[index]] = c[col.data_field.toLowerCase()];
        });
        return newDict;
      });
      if (process.env.NODE_ENV === "development") {
        console.log({ uniqueOrderedKeys, namedData });
      }
      setUpDownload({
        data: namedData,
        orderedColumns: uniqueOrderedKeys,
        chatId: currentOpenChatId,
      });
    } else {
      const uniqueColumns = getUniqueColumns(companyList);
      const data = reorderCompanyList(companyList);

      const orderedColumns = uniqueColumns
        .map((c) => c.toLowerCase())
        .sort(
          (a, b) => Number(a.includes("_##_")) - Number(b.includes("_##_")),
        );
      setUpDownload({ data, orderedColumns, chatId: currentOpenChatId });
    }
  } catch (error) {
    console.log(error);
  }
}
export function createPortalLink(
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
) {
  setLoading(true);
  // firebaseApp is object created using initializeApp()
  // may need to change server location
  const functions = getFunctions(firebase_app, "us-central1");
  const createPortalLink = httpsCallable(
    functions,
    "ext-firestore-stripe-payments-createPortalLink",
  );

  // request Stripe to create a portal link, and redirect user there
  createPortalLink({
    returnUrl: window.location.origin, // can set this to a custom page
  })
    .then((result) => {
      setLoading(false);
      window.location.assign(
        (result?.data as { url: string })?.url || window.location.href,
      );
    })
    .catch((error) => {
      // handle error
      setLoading(false);
      console.log(error);
    });
}
export const loadCheckout = async (
  userId: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  priceId: string,
  paymentMode: string,
) => {
  // console.log(userId);
  setLoading(true);
  const sessionsRef = collection(
    getFirestore(firebase_app),
    `users/${userId}/checkout_sessions`,
  );
  const docRef = await addDoc(sessionsRef, {
    price: priceId,
    success_url: window.location.href,
    cancel_url: window.location.href,
    mode: paymentMode,
    client_reference_id: priceId,
  });

  onSnapshot(docRef, async (snap) => {
    const { error, sessionId } = snap.data() as any;

    if (error) {
      alert(`An error occurred: ${error.message}`);
      setLoading(false);
    }

    if (sessionId) {
      const stripe = await loadStripe(
        process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? "",
      );
      stripe?.redirectToCheckout({ sessionId });
    }
  });
};

export function timeSince(date: Date) {
  const formatter = new Intl.RelativeTimeFormat(undefined, {
    numeric: "auto",
  });

  const DIVISIONS: { amount: number; name: Intl.RelativeTimeFormatUnit }[] = [
    // { amount: 60, name: "seconds" },
    // { amount: 60, name: "minutes" },
    // { amount: 24, name: "hours" },
    { amount: 7, name: "days" },
    { amount: 4.34524, name: "weeks" },
    { amount: 12, name: "months" },
    { amount: Number.POSITIVE_INFINITY, name: "years" },
  ];

  function formatTimeAgo(date: Date) {
    let duration =
      ((date as any) - (new Date() as any)) / (1000 * 60 * 60 * 24);

    for (let i = 0; i < DIVISIONS.length; i++) {
      const division = DIVISIONS[i];
      if (Math.abs(duration) < division.amount) {
        return formatter.format(Math.round(duration), division.name);
      }
      duration /= division.amount;
    }
  }
  return formatTimeAgo(date);
}

export function sentenceCase(content: string, splitBy: "_" | " " = " ") {
  return String(content)
    ?.split(splitBy)
    .map((s) => s && s.charAt(0)?.toUpperCase() + s?.substring(1))
    .join(" ");
}

export function sentenceCaseShort(
  content: string,
  splitBy: "_" | " " = " ",
  maxLength: number = 50,
) {
  const capitalizedSentence = String(content)
    ?.split(splitBy)
    .map((s) => s && s.charAt(0)?.toUpperCase() + s?.substring(1))
    .join(" ");

  // If the sentence is longer than maxLength, truncate and add ellipsis
  if (capitalizedSentence.length > maxLength) {
    return capitalizedSentence.slice(0, maxLength) + "...";
  }

  return capitalizedSentence;
}
export function isCompanyNameColumn(col: string) {
  if (col.toLowerCase() === "company_name") return true;
  if (col.toLowerCase() === "company") return true;
  if (col.toLowerCase().includes("company name")) return true;
  if (col.toLowerCase().includes("name")) return true;
  return false;
}
const levenshteinDistance = (s: string, t: string) => {
  if (!s.length) return t.length;
  if (!t.length) return s.length;
  const arr = [];
  for (let i = 0; i <= t.length; i++) {
    arr[i] = [i];
    for (let j = 1; j <= s.length; j++) {
      arr[i][j] =
        i === 0
          ? j
          : Math.min(
              arr[i - 1][j] + 1,
              arr[i][j - 1] + 1,
              arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1),
            );
    }
  }
  return arr[t.length][s.length];
};

export function getCompanyNameColumn(columns: string[]) {
  return columns
    .map((c) => ({
      col_name: c,
      distance: levenshteinDistance(c, "company_name"),
    }))
    .sort((a, b) => a.distance - b.distance)
    .at(0)?.col_name;
}
export function getIndustryColumn(columns: string[]) {
  return columns
    .filter((col) => {
      if (col.toLowerCase() === "industry") return true;
      return false;
    })
    .at(0);
}
export function getLocationColumn(columns: string[]) {
  return columns
    .filter((col) => {
      if (col.toLowerCase() === "location") return true;
      return false;
    })
    .at(0);
}
export function removeItemOnce(arr: string[], value: string) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}
export function fetchTools(
  tokenPromise: Promise<string>,
): Promise<KurationTool[]> {
  return backend_services
    .fetchProtectedData(`/tools`, tokenPromise, undefined, "GET")
    .then((res) => res.json());
}
export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
export function reorderColumns(columns: string[]) {
  const localColumns = [...columns];
  const domainColumn = getDomainNameColumn(localColumns);
  const companyNameColumn = getCompanyNameColumn(localColumns);
  // const industryColumn = getIndustryColumn(localColumns);
  // const locationColumn = getLocationColumn(localColumns);

  if (domainColumn) {
    removeItemOnce(localColumns, domainColumn);
    localColumns.unshift(domainColumn);
  }
  if (companyNameColumn) {
    removeItemOnce(localColumns, companyNameColumn);
    localColumns.unshift(companyNameColumn);
  }
  return localColumns;
}
export function domainName(columns: string[], queries: string[]) {
  let name = null;
  for (let index = 0; index < queries.length; index++) {
    const element = queries[index];
    name = columns.find((col) => col.toLowerCase().includes(element));
    if (name) {
      break;
    }
  }
  return name;
}
export function getDomainNameColumn(columns: string[]) {
  return domainName(columns, [
    "website",
    "linkedin_url",
    "link",
    "url",
    "domain",
    "online_presence",
    "online presence",
  ]);
}

export function getColumnDisplayName(col: Column) {
  if (col.display_name) {
    return col.display_name;
  }
  if (
    col.type === "tool_column" &&
    col.tool.id === "key_value_extractor" &&
    col.column_data["json_key_select"]
  ) {
    return col.column_data["json_key_select"];
  }
  return col.type === "normal" || col.type === "research_plan_column"
    ? sentenceCase(col.data_field, "_")
    : getToolDisplay(col);
}
export function getToolDisplay(col: KurationToolColumn | CustomToolColumn) {
  return col.type === "custom_tool_column"
    ? col.custom_tool.fields.name
    : col.tool.display_field
      ? col.column_data[col.tool.display_field]
      : col.tool.name;
}

export const getSubscription = async (userId: string) => {
  const userRef = doc(getFirestore(firebase_app), `users/${userId}`);
  const stripeId = (await getDoc(userRef)).get("stripeId");
  const stripe = new Stripe(
    `${process.env.REACT_APP_STRIPE_READ_SUBSCRIPTIONS_KEY}`,
  );
  // Retrieve all subscriptions for the customer
  const subscriptions = await stripe.subscriptions.list({
    customer: stripeId,
  });

  if (subscriptions.data.length > 0) {
    const subscription = subscriptions.data[0];
    // Loop through all items in the subscription
    const item = subscription.items.data[0];
    // Fetch the price associated with the subscription item
    const price = await stripe.prices.retrieve(item.price.id);
    // Fetch the product associated with the price
    const productId =
      typeof price.product === "string" ? price.product : price.product.id;
    const product = await stripe.products.retrieve(productId);
    return {
      id: subscription.id,
      name: product.name,
      description: product.description,
    };
  } else {
    return {
      id: "",
      name: "Free",
      description: "50 complimentary AI-enabled queries",
    };
  }
};

export const cancelSubscription = async (id: string) => {
  const stripe = new Stripe(
    `${process.env.REACT_APP_STRIPE_READ_SUBSCRIPTIONS_KEY}`,
  );
  return await stripe.subscriptions.cancel(id);
};

export const getLifetimePlan = async (userId: string) => {
  const userRef = doc(getFirestore(firebase_app), `users/${userId}`);
  const lifetimePlan = (await getDoc(userRef)).get("lifetimePlan");
  return lifetimePlan;
};

export const updateUserInfo = async (userId: string, data: any) => {
  try {
    const docRef = doc(getFirestore(firebase_app), `users/${userId}`);
    await setDoc(docRef, data);
  } catch (error) {
    console.log(error);
  }
};

export async function saveEmail(email: string, tokenPromise: Promise<string>) {
  const result = await backend_services.fetchProtectedData(
    `/user/email`,
    tokenPromise,
    undefined,
    "POST",
    { email: email },
  );
  if (!result || result.status !== 200) {
    console.log("something went wrong", result);
  }
}

export const countries = [
  { code: "AF", name: "Afghanistan" },
  { code: "AL", name: "Albania" },
  { code: "DZ", name: "Algeria" },
  { code: "AS", name: "American Samoa" },
  { code: "AD", name: "Andorra" },
  { code: "AO", name: "Angola" },
  { code: "AI", name: "Anguilla" },
  { code: "AG", name: "Antigua and Barbuda" },
  { code: "AR", name: "Argentina" },
  { code: "AM", name: "Armenia" },
  { code: "AU", name: "Australia" },
  { code: "AT", name: "Austria" },
  { code: "AZ", name: "Azerbaijan" },
  { code: "BS", name: "Bahamas" },
  { code: "BH", name: "Bahrain" },
  { code: "BD", name: "Bangladesh" },
  { code: "BB", name: "Barbados" },
  { code: "BY", name: "Belarus" },
  { code: "BE", name: "Belgium" },
  { code: "BZ", name: "Belize" },
  { code: "BJ", name: "Benin" },
  { code: "BM", name: "Bermuda" },
  { code: "BT", name: "Bhutan" },
  { code: "BO", name: "Bolivia" },
  { code: "BA", name: "Bosnia and Herzegovina" },
  { code: "BW", name: "Botswana" },
  { code: "BR", name: "Brazil" },
  { code: "BN", name: "Brunei" },
  { code: "BG", name: "Bulgaria" },
  { code: "BF", name: "Burkina Faso" },
  { code: "BI", name: "Burundi" },
  { code: "KH", name: "Cambodia" },
  { code: "CM", name: "Cameroon" },
  { code: "CA", name: "Canada" },
  { code: "CV", name: "Cape Verde" },
  { code: "KY", name: "Cayman Islands" },
  { code: "CF", name: "Central African Republic" },
  { code: "TD", name: "Chad" },
  { code: "CL", name: "Chile" },
  { code: "CN", name: "China" },
  { code: "CO", name: "Colombia" },
  { code: "KM", name: "Comoros" },
  { code: "CG", name: "Congo - Brazzaville" },
  { code: "CD", name: "Congo - Kinshasa" },
  { code: "CR", name: "Costa Rica" },
  { code: "HR", name: "Croatia" },
  { code: "CU", name: "Cuba" },
  { code: "CY", name: "Cyprus" },
  { code: "CZ", name: "Czechia" },
  { code: "DK", name: "Denmark" },
  { code: "DJ", name: "Djibouti" },
  { code: "DM", name: "Dominica" },
  { code: "DO", name: "Dominican Republic" },
  { code: "EC", name: "Ecuador" },
  { code: "EG", name: "Egypt" },
  { code: "SV", name: "El Salvador" },
  { code: "GQ", name: "Equatorial Guinea" },
  { code: "ER", name: "Eritrea" },
  { code: "EE", name: "Estonia" },
  { code: "SZ", name: "Eswatini" },
  { code: "ET", name: "Ethiopia" },
  { code: "FJ", name: "Fiji" },
  { code: "FI", name: "Finland" },
  { code: "FR", name: "France" },
  { code: "GA", name: "Gabon" },
  { code: "GM", name: "Gambia" },
  { code: "GE", name: "Georgia" },
  { code: "DE", name: "Germany" },
  { code: "GH", name: "Ghana" },
  { code: "GR", name: "Greece" },
  { code: "GD", name: "Grenada" },
  { code: "GU", name: "Guam" },
  { code: "GT", name: "Guatemala" },
  { code: "GN", name: "Guinea" },
  { code: "GW", name: "Guinea-Bissau" },
  { code: "GY", name: "Guyana" },
  { code: "HT", name: "Haiti" },
  { code: "HN", name: "Honduras" },
  { code: "HU", name: "Hungary" },
  { code: "IS", name: "Iceland" },
  { code: "IN", name: "India" },
  { code: "ID", name: "Indonesia" },
  { code: "IR", name: "Iran" },
  { code: "IQ", name: "Iraq" },
  { code: "IE", name: "Ireland" },
  { code: "IL", name: "Israel" },
  { code: "IT", name: "Italy" },
  { code: "JM", name: "Jamaica" },
  { code: "JP", name: "Japan" },
  { code: "JO", name: "Jordan" },
  { code: "KZ", name: "Kazakhstan" },
  { code: "KE", name: "Kenya" },
  { code: "KI", name: "Kiribati" },
  { code: "KR", name: "South Korea" },
  { code: "KW", name: "Kuwait" },
  { code: "KG", name: "Kyrgyzstan" },
  { code: "LA", name: "Laos" },
  { code: "LV", name: "Latvia" },
  { code: "LB", name: "Lebanon" },
  { code: "LS", name: "Lesotho" },
  { code: "LR", name: "Liberia" },
  { code: "LY", name: "Libya" },
  { code: "LI", name: "Liechtenstein" },
  { code: "LT", name: "Lithuania" },
  { code: "LU", name: "Luxembourg" },
  { code: "MG", name: "Madagascar" },
  { code: "MW", name: "Malawi" },
  { code: "MY", name: "Malaysia" },
  { code: "MV", name: "Maldives" },
  { code: "ML", name: "Mali" },
  { code: "MT", name: "Malta" },
  { code: "MH", name: "Marshall Islands" },
  { code: "MR", name: "Mauritania" },
  { code: "MU", name: "Mauritius" },
  { code: "MX", name: "Mexico" },
  { code: "FM", name: "Micronesia" },
  { code: "MD", name: "Moldova" },
  { code: "MC", name: "Monaco" },
  { code: "MN", name: "Mongolia" },
  { code: "ME", name: "Montenegro" },
  { code: "MA", name: "Morocco" },
  { code: "MZ", name: "Mozambique" },
  { code: "MM", name: "Myanmar" },
  { code: "NA", name: "Namibia" },
  { code: "NR", name: "Nauru" },
  { code: "NP", name: "Nepal" },
  { code: "NL", name: "Netherlands" },
  { code: "NZ", name: "New Zealand" },
  { code: "NI", name: "Nicaragua" },
  { code: "NE", name: "Niger" },
  { code: "NG", name: "Nigeria" },
  { code: "NO", name: "Norway" },
  { code: "OM", name: "Oman" },
  { code: "PK", name: "Pakistan" },
  { code: "PW", name: "Palau" },
  { code: "PS", name: "Palestine" },
  { code: "PA", name: "Panama" },
  { code: "PG", name: "Papua New Guinea" },
  { code: "PY", name: "Paraguay" },
  { code: "PE", name: "Peru" },
  { code: "PH", name: "Philippines" },
  { code: "PL", name: "Poland" },
  { code: "PT", name: "Portugal" },
  { code: "QA", name: "Qatar" },
  { code: "RO", name: "Romania" },
  { code: "RU", name: "Russia" },
  { code: "RW", name: "Rwanda" },
  { code: "WS", name: "Samoa" },
  { code: "SM", name: "San Marino" },
  { code: "SA", name: "Saudi Arabia" },
  { code: "SN", name: "Senegal" },
  { code: "RS", name: "Serbia" },
  { code: "SC", name: "Seychelles" },
  { code: "SL", name: "Sierra Leone" },
  { code: "SG", name: "Singapore" },
  { code: "SK", name: "Slovakia" },
  { code: "SI", name: "Slovenia" },
  { code: "SB", name: "Solomon Islands" },
  { code: "SO", name: "Somalia" },
  { code: "ZA", name: "South Africa" },
  { code: "SS", name: "South Sudan" },
  { code: "ES", name: "Spain" },
  { code: "LK", name: "Sri Lanka" },
  { code: "SD", name: "Sudan" },
  { code: "SR", name: "Suriname" },
  { code: "SE", name: "Sweden" },
  { code: "CH", name: "Switzerland" },
  { code: "SY", name: "Syria" },
  { code: "TW", name: "Taiwan" },
  { code: "TJ", name: "Tajikistan" },
  { code: "TZ", name: "Tanzania" },
  { code: "TH", name: "Thailand" },
  { code: "TL", name: "Timor-Leste" },
  { code: "TG", name: "Togo" },
  { code: "TO", name: "Tonga" },
  { code: "TT", name: "Trinidad and Tobago" },
  { code: "TN", name: "Tunisia" },
  { code: "TR", name: "Turkey" },
  { code: "TM", name: "Turkmenistan" },
  { code: "TV", name: "Tuvalu" },
  { code: "UG", name: "Uganda" },
  { code: "UA", name: "Ukraine" },
  { code: "AE", name: "United Arab Emirates" },
  { code: "GB", name: "United Kingdom" },
  { code: "US", name: "United States" },
  { code: "UY", name: "Uruguay" },
  { code: "UZ", name: "Uzbekistan" },
  { code: "VU", name: "Vanuatu" },
  { code: "VE", name: "Venezuela" },
  { code: "VN", name: "Vietnam" },
  { code: "YE", name: "Yemen" },
  { code: "ZM", name: "Zambia" },
  { code: "ZW", name: "Zimbabwe" },
];
