// src/context/AuthContext.tsx

import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getAuth,
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInAnonymously,
} from "firebase/auth";
import { UserCredential } from "firebase/auth/cordova";

// Define the type right here for the function
type SyncUserWithUserGuidingType = (user: User) => void;

// This will hold the dynamically imported function
let syncUserWithUserGuidingLocal: SyncUserWithUserGuidingType | undefined;

(async () => {
  const userGuiding = await import("src/context/userGuiding");
  syncUserWithUserGuidingLocal = userGuiding.syncUserWithUserGuiding;
})();

interface AuthContextType {
  appInitialized: boolean;
  user: User | null;
  signIn: (email: string, password: string) => Promise<UserCredential>;
  signUp: (email: string, password: string) => Promise<UserCredential>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [appInitialized, setAppInitialized] = useState(false);
  const auth = getAuth();
  const [user, setOutUser] = useState<User | null>(() => {
    const currUser = getAuth().currentUser;
    return currUser;
  });

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged(async (currentUser) => {
      setOutUser(currentUser);
      if (currentUser && syncUserWithUserGuidingLocal) {
        try {
          syncUserWithUserGuidingLocal(currentUser);
          console.log("User synced with UserGuiding");
        } catch (error) {
          console.log("Failed to sync user with UserGuiding");
          console.error("Error syncing with UserGuiding:", error);
        }
      } else {
        try {
          const result = await signInAnonymously(auth);
          console.log("Signed in as guest:", result.user.uid);
          setOutUser(result.user);
        } catch (error) {
          console.error("Anonymous sign-in failed:", error);
        }
      }
      setAppInitialized(true);
    });

    return () => unsubscribe();
  }, [auth]);

  const signIn = async (email: string, password: string) => {
    const userCredential = await signInWithEmailAndPassword(
      getAuth(),
      email,
      password,
    );
    if (syncUserWithUserGuidingLocal)
      syncUserWithUserGuidingLocal(userCredential.user);
    return userCredential;
  };

  const signUp = async (email: string, password: string) => {
    const userCredential = await createUserWithEmailAndPassword(
      getAuth(),
      email,
      password,
    );
    if (syncUserWithUserGuidingLocal)
      syncUserWithUserGuidingLocal(userCredential.user);
    return userCredential;
  };

  const signOut = async () => {
    await getAuth().signOut();
    // Assume `window.userGuiding` exists and has a `reset` method as previously set up in global types
    if (window.userGuiding && window.userGuiding.reset) {
      window.userGuiding.reset();
    }
  };

  return (
    <AuthContext.Provider
      value={{ appInitialized, user, signIn, signOut, signUp }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};