"use client";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "../context/StateContext";
import ToolbarToggleOpen from "./svgs/ToolbarToggleOpen";
import ToolbarButton from "./svgs/ToolBarButton";
import ToolbarToggleBackground from "./svgs/ToolBarToggleBackground";
import ToolbarToggleClose from "./svgs/ToolbarToggleClose";
import Sidebar from "../pages/sidebar/Sidebar";
import Navbar from "./NavBar";
import ChatIcon from "./svgs/Chat";
import TableIcon from "./svgs/Table";
import MainTable from "./table/MainTable";
import { useTutorialMode } from "../hooks/useTutorialMode";
import { twMerge } from "tailwind-merge";
import FullScreenLottie from "src/lotties/FullScreenLottie";
import { ListView } from "./preCuratedLists/ListView";
import { ListBuilderHome } from "./ListBuilderHome";
import ChatContainer from "src/pages/chat/ChatContainer";
import useOnboarding from "src/hooks/useOnboarding";
import { PrecuratedListFilters } from "./preCuratedLists/PreCuratedListFilters";

export default function ChatTableLayout() {
  const { sidebarOpen } = useStateContext();
  const { chatOpen, chatFullScreen } = useStateContext();
  const { tutorialOpen } = useTutorialMode();
  const { chatId } = useParams();

  const { appInitialized, user } = useAuth();
  const { onBoarding } = useOnboarding(user?.uid ?? null);

  const navigate = useNavigate();
  useEffect(() => {
    if (!appInitialized) {
      return;
    }

    if (onBoarding) {
      navigate("/onboarding");
    }
  }, [user, appInitialized, onBoarding, navigate]);

  if (!appInitialized) {
    return <FullScreenLottie />;
    // return <Spinner text="Kuration.io is loading..." />;
  }
  return (
    <div className="relative flex max-h-screen w-full flex-col overflow-hidden bg-white">
      <div className={twMerge("sticky top-0 z-40 w-full bg-dark md:hidden")}>
        <Navbar />
      </div>

      <div className="flex w-full flex-grow">
        {/* Sidebar wrapper - dynamic width based on state */}
        <div
          className={`hidden transition-all duration-300 ease-out md:block ${
            sidebarOpen ? "w-[268px]" : "w-20"
          }`}
        >
          <Sidebar />
        </div>

        {/* Mobile sidebar - absolute positioned */}
        <div className="md:hidden">
          <Sidebar />
        </div>

        {/* Main content area - adjusts with sidebar */}
        <div
          className={`flex-1 transition-all duration-300 ease-out ${
            sidebarOpen ? "md:ml-0" : "md:ml-0"
          }`}
        >
          <div className="flex h-[calc(100vh_-_78px)] flex-grow overflow-auto bg-bg-2 pr-0 pt-4 transition-all duration-300 ease-out md:h-screen md:p-9 md:pl-5 md:pr-0">
            <div className="max-h-full w-full overflow-auto">
              <CenterSection />
            </div>
          </div>
        </div>
        <div
          className={twMerge(
            "fixed right-5 z-[60] flex h-14 w-14 items-center justify-center rounded-full bg-bg-2 shadow-[2px_2px_20px_0px_#00000014] md:static md:z-10 md:h-screen md:w-[32px] md:rounded-none md:shadow-none",
            `${tutorialOpen ? "hidden md:flex" : ""}`,
            `${!chatId ? "hidden md:flex" : ""} `,
            `${chatOpen ? "top-24 md:flex" : "bottom-6 flex"}`,
          )}
        >
          <ChatToggle />
        </div>
        <main
          aria-hidden={!chatOpen}
          className={twMerge(
            "h-[calc(100vh_-_78px)] flex-grow overflow-auto border-grey-400 bg-bg-1 transition-transform duration-300 ease-out md:h-screen md:border-l",
            chatFullScreen
              ? "visible w-[90%]"
              : chatOpen
                ? "visible md:max-w-[560px]"
                : "invisible max-w-0 translate-x-full",
          )}
        >
          <RightSide />
        </main>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
function CenterSection() {
  const { chatId, listId } = useParams();

  if (!chatId) {
    if (listId) {
      return <ListView />;
    }
    return <ListBuilderHome />;
  }
  return <MainTable />;
}
function RightSide() {
  const { listId } = useParams();

  if (listId) {
    return <PrecuratedListFilters />;
  }

  return <ChatContainer />;
}

function ChatToggle() {
  const { chatOpen, setChatOpen, chatFullScreen } = useStateContext();
  return (
    <button
      onClick={(e) => {
        setChatOpen(!chatOpen);
        e.currentTarget?.blur();
      }}
      className={`${chatOpen && chatFullScreen ? "hidden" : ""}`}
    >
      <div className="flex h-14 w-14 items-center justify-center md:hidden">
        {chatOpen ? <TableIcon /> : <ChatIcon />}
      </div>
      <div className="group relative hidden h-[40px] w-full items-center justify-center py-1 md:flex">
        <ToolbarButton
          className={`${
            chatOpen ? "" : "hidden"
          }  absolute opacity-100 transition-all duration-300 ease-out  group-hover:fill-black group-hover:opacity-0 group-focus:fill-black group-focus:opacity-0`}
        />
        <ToolbarToggleOpen
          className={`${
            chatOpen ? "hidden" : ""
          } absolute opacity-100 transition-all duration-300 ease-out  group-hover:fill-black group-focus:fill-black `}
        />
        <ToolbarToggleClose
          className={` ${
            chatOpen ? "" : "hidden"
          }  absolute  opacity-0 transition-all duration-300 ease-out  group-hover:fill-black group-hover:opacity-100 group-focus:fill-black group-focus:opacity-100`}
        />
        <div className="absolute right-full top-0 flex items-center justify-center align-middle text-white">
          <div className="relative mr-4 flex h-[40px] w-[137px] items-center pl-[11px] pr-[18px] opacity-0 transition-all duration-300 ease-out group-hover:opacity-100 group-focus:opacity-100">
            <ToolbarToggleBackground className="absolute inset-0 z-[60]" />
            <span className=" z-[60] text-lg font-normal text-white">
              {chatOpen ? "Close" : "Open"} Chat
            </span>
          </div>
        </div>
      </div>
    </button>
  );
}
