import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
// import { useSearchParams } from "react-router-dom";
import { IconButton } from "src/components/elements/IconButton";
import ChatIcon from "src/components/svgs/Chat";
import { fetchTools } from "src/components/utils";
import { useAuth } from "src/context/AuthContext";
import { useStateContext } from "src/context/StateContext";
import { FiltersMain } from "./FiltersMain";
import { KurationFilterDetails } from "./KurationFilterDetails";
import BackIcon from "src/components/svgs/Back";
import { CustomFilterAddEditForm } from "./CustomFilterForm";
import { CustomTool, KurationTool, Tool } from "src/models/tools";
import useChatDetails from "src/hooks/useChatDetails";
import useDefaultTable, { Recipe } from "src/hooks/useChatTable";
import { useParams } from "react-router-dom";
import useAllCustomTools from "src/hooks/useAllCustomTools";
import { CustomFilterDetails } from "./CustomFilterDetails";
import { FilterFormField } from "../../../src/components/preCuratedLists/FilterFields";
import { useListFilterQuery } from "src/hooks/useSearchQuery";
import { RecipeDetails } from "./RecipeDetails";

export function FiltersLibrary({ isInsidePopup, closeFiltersLibrary }: { isInsidePopup: boolean, closeFiltersLibrary: ()=>void }) {
  const { setFiltersOpen } = useStateContext();
  const { user } = useAuth();
  const [showCreateCustomFilter, setShowCreateCustomFilter] = useState(false);
  const [selectedTool, setSelectedTool] = useState<Tool | null>(null);
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe | null>(null);
  const { chatId } = useParams();
  // const [, setSearchParams] = useSearchParams();
  const { chatDetails } = useChatDetails(chatId ?? null);
  const tableId = chatDetails?.table_id;
  const { data: defaultTable } = useDefaultTable(tableId);
  const columns = defaultTable?.column_list || []; // Dynamically fetched columns
  const { loading: customToolsLoading, data: customTools } =
    useAllCustomTools();
  const listFilter = useListFilterQuery();
  const [filterForm, setFilterForm] = useState<FilterFormField[]>(listFilter);
  const {
    isLoading,
    error,
    refetch,
    data: tools,
  } = useQuery({
    queryKey: ["tools"],
    refetchOnWindowFocus: false,
    queryFn: () => user && fetchTools(user?.getIdToken()),
  });

  return (
    <div className="flex h-full flex-1 flex-col">
      {!isInsidePopup && (
        <div className="flex items-start border-b border-neutral-300 py-9 pl-6">
          <IconButton
            icon={<ChatIcon />}
            variant="outline"
            onClick={() => {
              setFiltersOpen(false);
              setShowCreateCustomFilter(false);
              // submit(filterForm);
            }}
            text={"Back to the chat"}
            overrideClasses="w-max gap-2 border-none bg-grey-600 p-3 text-base font-normal text-neutral-900"
          />
        </div>
      )}
      {(!!selectedTool || !!showCreateCustomFilter || !!selectedRecipe) && (
        <div className="flex h-[72px] w-full flex-col items-start justify-center gap-[33px] border-b border-neutral-300 bg-white py-6 pl-[23px] pr-6">
          <div className="flex items-center justify-start gap-3">
            <button
              className="flex h-[42px] w-[42px] items-center justify-center rounded-md transition-all duration-300 hover:bg-lightpurple"
              onClick={() => {
                setSelectedTool(null);
                setShowCreateCustomFilter(false);
                setSelectedRecipe(null);
              }}
            >
              <BackIcon className="h-4 w-4" />
            </button>
            <div className="leading-none">Enrichment library</div>
          </div>
        </div>
      )}
      {showCreateCustomFilter ? (
        <CustomFilterAddEditForm
          columns={columns}
          onSuccess={() => {
            setShowCreateCustomFilter(false);
          }}
        />
      ) : (
        <div className="px-4 py-8">
          {selectedTool ? (
            <div className="flex flex-1 flex-col">
              {Object.hasOwn(selectedTool, "name") ? (
                <KurationFilterDetails
                  selectedTool={selectedTool as KurationTool}
                />
              ) : (
                <CustomFilterDetails
                  selectedTool={selectedTool as CustomTool}
                  unselectTool={() => {
                    setSelectedTool(null);
                  }}
                />
              )}
            </div>
          ) : selectedRecipe ? (
            <RecipeDetails recipe={selectedRecipe} />
          ) : (
            <FiltersMain
              isInsidePopup={isInsidePopup}
              isLoading={isLoading}
              customToolsLoading={customToolsLoading}
              customTools={customTools}
              error={error}
              tools={tools ?? []}
              retryTools={() => {
                refetch();
              }}
              setShowCreateCustomFilter={setShowCreateCustomFilter}
              setSelectedRecipe={setSelectedRecipe}
              setSelectedTool={setSelectedTool}
              filterForm={filterForm}
              setFilterForm={setFilterForm}
            />
          )}
        </div>
      )}
    </div>
  );
}
