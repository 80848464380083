import * as React from "react";
const KurationGridView = ({ stroke = "", ...props }) => (
  <svg
    {...props}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3327 27.2V19.4667C19.3327 19.2545 19.417 19.051 19.567 18.901C19.717 18.751 19.9205 18.6667 20.1327 18.6667H27.866C28.0782 18.6667 28.2817 18.751 28.4317 18.901C28.5817 19.051 28.666 19.2545 28.666 19.4667V27.2C28.666 27.4122 28.5817 27.6157 28.4317 27.7657C28.2817 27.9157 28.0782 28 27.866 28H20.1327C19.9205 28 19.717 27.9157 19.567 27.7657C19.417 27.6157 19.3327 27.4122 19.3327 27.2ZM4.66602 27.2V19.4667C4.66602 19.2545 4.7503 19.051 4.90033 18.901C5.05036 18.751 5.25384 18.6667 5.46602 18.6667H13.1993C13.4115 18.6667 13.615 18.751 13.765 18.901C13.9151 19.051 13.9993 19.2545 13.9993 19.4667V27.2C13.9993 27.4122 13.9151 27.6157 13.765 27.7657C13.615 27.9157 13.4115 28 13.1993 28H5.46602C5.25384 28 5.05036 27.9157 4.90033 27.7657C4.7503 27.6157 4.66602 27.4122 4.66602 27.2ZM19.3327 12.5333V4.8C19.3327 4.58783 19.417 4.38434 19.567 4.23431C19.717 4.08429 19.9205 4 20.1327 4H27.866C28.0782 4 28.2817 4.08429 28.4317 4.23431C28.5817 4.38434 28.666 4.58783 28.666 4.8V12.5333C28.666 12.7455 28.5817 12.949 28.4317 13.099C28.2817 13.249 28.0782 13.3333 27.866 13.3333H20.1327C19.9205 13.3333 19.717 13.249 19.567 13.099C19.417 12.949 19.3327 12.7455 19.3327 12.5333ZM4.66602 12.5333V4.8C4.66602 4.58783 4.7503 4.38434 4.90033 4.23431C5.05036 4.08429 5.25384 4 5.46602 4H13.1993C13.4115 4 13.615 4.08429 13.765 4.23431C13.9151 4.38434 13.9993 4.58783 13.9993 4.8V12.5333C13.9993 12.7455 13.9151 12.949 13.765 13.099C13.615 13.249 13.4115 13.3333 13.1993 13.3333H5.46602C5.25384 13.3333 5.05036 13.249 4.90033 13.099C4.7503 12.949 4.66602 12.7455 4.66602 12.5333Z"
      stroke={stroke}
      strokeWidth="1.5"
    />
  </svg>
);
export default KurationGridView;
