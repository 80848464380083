// import { InitialListItem } from "src/models/InitialList";
// import { useState, useRef, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import CSVFileUploader from "./CSVFileUploader";
// import UploadIcon from "../svgs/Upload";
// import ActionIcon from "../svgs/Action";
import StarsIcon from "../svgs/Stars";
import HomeTabs from "./homepage/HomeTabs";
// import { CreateAndSendMessage } from "src/models/data";

export function HomeLists() {
  // const chatContainerRef = useRef<{
  //   createAndSendMessage: CreateAndSendMessage;
  // }>(null);
  // const [loading, setLoading] = useState(false);
  // const [userUploadedJson, setUserUploadedJson] = useState<
  //   Record<string, string>[] | null
  // >(null);

  // const handleSendMessage = async (msg: string) => {
  //   if (chatContainerRef.current) {
  //     setLoading(true);
  //     try {
  //       await chatContainerRef.current.createAndSendMessage(
  //         msg,
  //         userUploadedJson,
  //       );
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  // };

  return (
    <div className="grid h-full grid-rows-[min-content,1fr] gap-10 px-5">
      <div className="linear-background-ai-assistance flex h-[200px] flex-col items-start justify-center rounded-2xl p-8">
        <p className="text-4xl font-normal text-neutral-900">
          Get Started with AI Assistance
        </p>
        <p className="text-md mt-2 font-light text-[#4F4F4F]">
          Engage with the assistant to refine your requirements and receive a
          tailored list of companies that best match your needs.
        </p>
        <button className="mt-4 flex gap-1 rounded-md bg-primary p-3 text-white">
          <StarsIcon fill="white" />
          Coming Soon
        </button>
      </div>

      <HomeTabs />

      {/* <div className="flex flex-wrap justify-start gap-4">
        {items.map((item) => (
          <InitialCard key={item._id} item={item} />
        ))}
      </div> */}
    </div>
  );
}

// const InitialCard = ({ item }: { item: InitialListItem }) => {
//   const [showQueryBox, setShowQueryBox] = useState(false);
//   const queryBoxRef = useRef<HTMLDivElement>(null);
//   const navigate = useNavigate();

//   function handleClick() {
//     if (item.name === "startFromScratch") {
//       navigate("/list-builder");
//     } else {
//       setShowQueryBox(true);
//     }
//   }

//   function handleClose() {
//     setShowQueryBox(false);
//   }

//   useEffect(() => {
//     function handleClickOutside(event: MouseEvent) {
//       if (
//         queryBoxRef.current &&
//         !queryBoxRef.current.contains(event.target as Node)
//       ) {
//         handleClose();
//       }
//     }

//     if (showQueryBox) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [showQueryBox]);

//   return (
//     <div>
//       <button
//         className="flex flex-col items-center justify-center gap-4 rounded-lg border-b-4 border-gray-200 bg-gray-200 p-4 transition-all hover:shadow-lg"
//         onClick={handleClick}
//         style={{ width: "300px", height: "180px" }}
//       >
//         <div className="flex flex-col items-center gap-2">
//           <div className="flex h-16 w-16 items-center justify-center rounded-full bg-white p-2">
//             {item.name === "startFromScratch" ? (
//               <img
//                 src="https://cdn.prod.website-files.com/64acc1670486f7df09be2423/67335ee61378e50e6904117c_Manually.svg"
//                 alt="CSV Uploader"
//                 className="h-8 w-8 rounded-full object-cover"
//               />
//             ) : (
//               <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-full">
//                 {item.icon}
//               </div>
//             )}
//           </div>
//           <div className="flex flex-col items-center justify-center text-center">
//             <div className="text-lg font-normal text-gray-900">
//               {item.description}
//             </div>
//             <p className="card-subtitle"></p>
//             <div className="text-sm font-light text-gray-600">
//               {item.subtitle}
//             </div>
//           </div>
//         </div>
//       </button>

//       {showQueryBox && (
//         <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//           <div
//             ref={queryBoxRef}
//             className="relative rounded-lg bg-white p-6 shadow-lg"
//           >
//             {item.name === "csvFileUploader" && (
//               <CSVFileUploader item={item} handleClose={handleClose} />
//             )}
//             <button
//               className="absolute right-2 top-2 text-gray-600 hover:text-gray-900"
//               onClick={handleClose}
//             >
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 className="h-6 w-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };
