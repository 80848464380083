import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

export interface FreePaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export const FreePagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: FreePaginationProps) => {
  const [inputPage, setInputPage] = useState("");

  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
      setInputPage("");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputPage(value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const page = parseInt(inputPage);
      if (!isNaN(page)) {
        goToPage(page);
      }
    }
  };

  const goToFirstPage = () => goToPage(1);
  const goToLastPage = () => goToPage(totalPages);
  const goToNextPage = () => goToPage(currentPage + 1);
  const goToPrevPage = () => goToPage(currentPage - 1);

  const buttonStyle = "rounded-md bg-white border border-gray-300 px-4 py-2 text-gray-700 hover:bg-gray-50 active:bg-gray-100 transition-all duration-200 shadow-sm";
  const pageButtonStyle = "rounded-md min-w-[2rem] px-3 py-1.5 text-sm font-medium transition-all duration-200";
  const disabledStyle = "cursor-not-allowed opacity-40 hover:bg-white active:bg-white";
  const activePageStyle = "bg-primary text-white border border-primary hover:bg-blue-700";
  const inactivePageStyle = "bg-white text-gray-700 border border-gray-300 hover:bg-gray-50 active:bg-gray-100";

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={twMerge(
            pageButtonStyle,
            i === currentPage ? activePageStyle : inactivePageStyle
          )}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="flex items-center gap-3">
      <div className="inline-flex items-center justify-center gap-1.5 p-1 bg-gray-50 rounded-lg">
        <button
          onClick={goToFirstPage}
          disabled={currentPage === 1}
          className={twMerge(buttonStyle, currentPage === 1 && disabledStyle)}
          title="First Page"
        >
          <span className="flex">
          <ChevronLeftIcon className="inline" width={12} />
          <ChevronLeftIcon width={12} />
          </span>
          
        </button>
        <button
          onClick={goToPrevPage}
          disabled={currentPage === 1}
          className={twMerge(buttonStyle, currentPage === 1 && disabledStyle)}
          title="Previous Page"
        >
          <ChevronLeftIcon width={20} />
        </button>

        <div className="flex items-center gap-1.5 px-1">{renderPageNumbers()}</div>

        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className={twMerge(buttonStyle, currentPage === totalPages && disabledStyle)}
          title="Next Page"
        >
          <ChevronRightIcon width={20} />
        </button>
        <button
          onClick={goToLastPage}
          disabled={currentPage === totalPages}
          className={twMerge(buttonStyle, currentPage === totalPages && disabledStyle)}
          title="Last Page"
        >
          <span className="flex">
            
          <ChevronRightIcon className="inline" width={12} />
          <ChevronRightIcon width={12} />
          </span>
        </button>
      </div>

      <div className="flex items-center gap-2">
        <input
          type="number"
          value={inputPage}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          placeholder="Go to page"
          className="w-28 rounded-md border border-gray-300 px-3 py-1.5 text-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20 focus:outline-none transition-all duration-200"
          min={1}
          max={totalPages}
        />
        <button
          onClick={() => {
            const page = parseInt(inputPage);
            if (!isNaN(page)) {
              goToPage(page);
            }
          }}
          className={buttonStyle}
          disabled={!inputPage}
        >
          Go
        </button>
      </div>
    </div>
  );
};

export default FreePagination;

