import * as React from "react";
const KurationPencil = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.1708 2.73582L10.8881 0.441371C10.7372 0.291285 10.5331 0.207031 10.3203 0.207031C10.1075 0.207031 9.90335 0.291285 9.7525 0.441371L1.65972 8.52248L0.920834 11.7114C0.895345 11.8279 0.896219 11.9487 0.923392 12.0649C0.950565 12.1811 1.00335 12.2898 1.07789 12.3829C1.15243 12.4761 1.24685 12.5515 1.35424 12.6035C1.46163 12.6555 1.57929 12.6829 1.69861 12.6836C1.75421 12.6892 1.81024 12.6892 1.86583 12.6836L5.08972 11.9447L13.1708 3.87137C13.3209 3.72052 13.4052 3.51639 13.4052 3.30359C13.4052 3.0908 13.3209 2.88666 13.1708 2.73582ZM4.70083 11.2447L1.67917 11.8786L2.3675 8.91526L8.4225 2.88359L10.7558 5.21693L4.70083 11.2447ZM11.2769 4.65304L8.94361 2.3197L10.2969 0.974149L12.5914 3.30748L11.2769 4.65304Z"
      fill="#705CF6"
    />
  </svg>
);

export default KurationPencil;
