import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { twMerge } from "tailwind-merge";

export interface PaginationProps {
  currentPage: number;
  hasNextPage: boolean;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  totalPages: number;
}

export const Pagination = ({
  currentPage,
  handleNextPage,
  handlePreviousPage,
  hasNextPage,
  totalPages,
}: PaginationProps) => {
  const goToNextPage = () => {
    handleNextPage();
  };

  const goToPrevPage = () => {
    handlePreviousPage();
  };

  return (
    <div className="flex items-center justify-center">
      <button
        onClick={goToPrevPage}
        className="mr-2 cursor-pointer rounded-md bg-gray-300 px-4 py-2 text-gray-700 active:scale-90 transition-all duration-300"
      >
        <ChevronLeftIcon width={20} />
      </button>
      <span className="mx-2">
        Page {currentPage} {totalPages !== null && `of ${totalPages}`}
      </span>
      <button
        onClick={goToNextPage}
        disabled={!hasNextPage}
        className={twMerge(
          `active:scale-90 transition-all duration-300 ml-2 bg-gray-300 px-4 py-2`,
          `${!hasNextPage ? "cursor-not-allowed  text-gray-700" : " "} rounded-md`,
        )}
      >
        <ChevronRightIcon width={20} />
      </button>
    </div>
  );
};


export interface FreePaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export default Pagination;
