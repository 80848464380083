"use client";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Input from "./Input";
import { useLocation } from "react-router-dom";
import FormAction from "./FormAction";
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  linkWithPopup,
  signInWithCredential,
  signInWithPopup,
} from "firebase/auth";
import { loginFields } from "./formFields";
import { useAuth } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { addUserToLoop, createDataForNewUser } from "./api-loop_os";
import Spinner from "../elements/Spinner";
import { saveEmail } from "../utils";

const fields = loginFields;
let fieldsState: { [key: string]: any } = {};
fields.forEach((field) => (fieldsState[field.name] = ""));

export default function Login() {
  const location = useLocation();
  const [loginState, setLoginState] = useState(fieldsState);
  const { signIn, user } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [timer, setTimer] = useState<number | null>(null);

  useEffect(() => {
    if (!(error.length > 1)) {
      return;
    }
    const timeId = setTimeout(() => {
      setError("");
    }, 5000);
    setTimer(() => timeId as unknown as number);
    return () => {
      timer && clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [error]);

  const handleChange = (e: ChangeEvent) => {
    setLoginState({
      ...loginState,
      [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement)
        .value,
    });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await authenticateUser();
  };

  const isLifetimeDeal = (): boolean => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.has("lifetimedeal");
  };

  const handleGoogleAuth = async () => {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      let userCred;

      if (auth.currentUser?.isAnonymous) {
        try {
          userCred = await linkWithPopup(auth.currentUser, provider);
          if (auth.currentUser.email) {
            saveEmail(auth.currentUser.email, auth.currentUser.getIdToken());
          } else {
            console.error("Email is null. Cannot save email.");
          }
        } catch (error) {
          if ((error as any).code === "auth/credential-already-in-use") {
            const credential = GoogleAuthProvider.credentialFromError(
              error as any,
            );
            if (credential) {
              userCred = await signInWithCredential(auth, credential);
              console.log("User signed in instead of linking");
            } else {
              throw error;
            }
          } else {
            throw error;
          }
        }
      } else {
        userCred = await signInWithPopup(auth, provider);
      }

      const { creationTime, lastSignInTime } = userCred.user.metadata;
      const lifetimeDeal = isLifetimeDeal();
      if (creationTime === lastSignInTime) {
        addUserToLoop(
          createDataForNewUser(userCred.user.email || ""),
          userCred.user.getIdToken(),
        );
        navigate("/onboarding");
      } else if (lifetimeDeal) {
        navigate("/earlybird");
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Login Failed:", error);
      setError(
        "Login Failed. Try again with correct credentials or Sign Up with a new account.",
      );
    }
  };

  const handleMicrosoftAuth = async () => {
    try {
      const auth = getAuth();
      const provider = new OAuthProvider("microsoft.com");

      let userCred;

      if (auth.currentUser?.isAnonymous) {
        try {
          userCred = await linkWithPopup(auth.currentUser, provider);
          if (auth.currentUser.email) {
            saveEmail(auth.currentUser.email, auth.currentUser.getIdToken());
          } else {
            console.error("Email is null. Cannot save email.");
          }
        } catch (error) {
          if ((error as any).code === "auth/credential-already-in-use") {
            const credential = OAuthProvider.credentialFromError(error as any);
            if (credential) {
              userCred = await signInWithCredential(auth, credential);
              console.log("User signed in instead of linking");
            } else {
              throw error;
            }
          } else {
            throw error;
          }
        }
      } else {
        userCred = await signInWithPopup(auth, provider);
      }

      const { creationTime, lastSignInTime } = userCred.user.metadata;
      const lifetimeDeal = isLifetimeDeal();

      if (creationTime === lastSignInTime) {
        addUserToLoop(
          createDataForNewUser(userCred.user.email || ""),
          userCred.user.getIdToken(),
        );
        navigate("/onboarding");
      } else if (lifetimeDeal) {
        navigate("/earlybird");
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Login Failed:", error);
      setError(
        "Login Failed. Try again with correct credentials or Sign Up with a new account.",
      );
    }
  };

  const authenticateUser = async () => {
    setLoading(true);
    try {
      const lifetimeDeal = isLifetimeDeal();
      await signIn(loginState["email"], loginState["password"]);
      if (user) {
      }
      if (lifetimeDeal) {
        navigate("/earlybird");
      } else {
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      // alert(error);

      console.log(error);
      console.error(error);
      setError(
        "Login Failed. Try again with correct credentials or Sign Up with new account.",
      );
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex w-full flex-col md:max-w-sm ">
        {loading && (
          <Spinner
            text="Logging In."
            styleClasses={["backdrop-blur-sm z-20"]}
          />
        )}
        {user?.isAnonymous === false && (
          <p className="my-5  text-center text-sm  text-grey-100">
            Signed In as: {user.displayName || user.email}{" "}
            <Link
              to={"/"}
              className="font-medium text-primary hover:text-primaryhover"
            >
              {"Go to Home"}
            </Link>
          </p>
        )}
        <div className="flex flex-col gap-3">
          <div className="flex justify-center">
            <button className="w-5/6 " onClick={handleGoogleAuth}>
              <div className="flex items-center justify-center gap-2 rounded-lg border border-grey-400 p-2">
                <img
                  height={30}
                  width={30}
                  src={"google.svg"}
                  alt="Login with Google"
                />
                <div className="w-[152.06px] text-start">
                  Sign In with Google
                </div>
              </div>
            </button>
          </div>

          <div className="flex justify-center">
            <button className="w-5/6 " onClick={handleMicrosoftAuth}>
              <div className="flex items-center justify-center gap-2 rounded-lg border border-grey-400 p-2">
                <img
                  height={30}
                  width={30}
                  src={
                    "https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
                  }
                  alt="Login with Microsoft"
                />
                <div>Sign In with Microsoft</div>
              </div>
            </button>
          </div>
        </div>

        <div className="relative my-4 flex h-3 w-full items-center justify-center text-center text-base text-black">
          <div className="absolute top-1/2 z-0 h-[1px] w-5/6 bg-grey-500"></div>
          <div className="z-10 h-6 bg-white px-2.5 leading-[27.4px]">OR</div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="flex flex-col">
            {fields.map((field) => (
              <Input
                key={field.id}
                handleChange={handleChange}
                value={loginState[field.name]}
                labelText={field.labelText}
                labelFor={field.labelFor}
                id={field.id}
                name={field.name}
                type={field.type}
                isRequired={field.isRequired}
                placeholder={field.placeholder}
                customClass={""}
              />
            ))}
          </div>
          <p>{error}</p>
          <FormAction action="submit" text="Continue" type="Button" />
        </form>
      </div>
    </div>
  );
}
